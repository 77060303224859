<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_DEPLOYMENT"
    title="Publication Deployment"
    :breadcrumbs="breadcrumbs"
    page-name="Deployment"
    align="left"
    width="full"
  >
    <iRow>
      <iColumn>
        <iRow>
          <iSubHeading>
            Production Version
          </iSubHeading>
        </iRow>
        <iRow v-if="productionVersionData.publication_version_id">
          <iColumn vertical-align="top">
            <iText> {{ productionVersionData.version }} </iText>
            <iText variant="subtle">
              {{ productionVersionData.memo }}
            </iText>
          </iColumn>
          <iColumn>
            <iLink :href="`/publication/${publicationId}/deployment/history`">
              <iButton variant="secondary">
                View Production History
              </iButton>
            </iLink>
          </iColumn>
        </iRow>
        <iRow v-else>
          Not deployed to production
        </iRow>
      </iColumn>
      <iColumn>
        <iRow>
          <iSubHeading>
            Staging Version
          </iSubHeading>
        </iRow>
        <iRow>
          <iColumn vertical-align="top">
            <iText>{{ stagingVersionData.version }}</iText>
            <iText variant="subtle">
              {{ stagingVersionData.memo }}
            </iText>
          </iColumn>
          <iColumn>
            <iLink :href="`/publication/${publicationId}/deployment/versions`">
              <iButton variant="secondary">
                View Staging History
              </iButton>
            </iLink>
          </iColumn>
        </iRow>
      </iColumn>
    </iRow>
    <iColumn v-if="publicationData.staging_version_id !== publicationData.production_version_id">
      <iTextArea v-model="memo" label="Changelog" />
      <iButton @click="publishCurrentConfig">
        Publish
      </iButton>
      <iText variant="subtle">
        *Staged changes to the associated theme & theme palette will also be published.
      </iText>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { useConfigurationStore } from "@/stores/configuration-store";

import PageWrapper from "@/components/PageWrapper";

import {
  PAGE_PUBLICATION_DEPLOYMENT,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "PublicationDeployment",
  components: {
    PageWrapper,
  },
  props: {
    publicationData: {
      type: Object,
      required: true,
    },
    stagingVersionData: {
      type: Object,
      required: true,
    },
    productionVersionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PAGE_PUBLICATION_DEPLOYMENT,
      SECTION_PUBLICATIONS,
      memo: "",
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publicationId", "orgId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["publishLatestStagingConfigToProduction"] ),
    publishCurrentConfig() {
      this.publishLatestStagingConfigToProduction(this.memo);
    },
  },
};
</script>
