<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN"
    title="Configure Sign Up & Login"
    :breadcrumbs="breadcrumbs"
    page-name="Sign Up & Login"
    align="left"
    width="full"
  >
    <iToggleSwitch v-model="signupAndLoginConfig.enabled" label="Activate Signup & Login" />
    <template v-if="signupAndLoginConfig.enabled">
      <iToggleSwitch v-model="signupAndLoginConfig.private_publication.enabled" label="Private Publication" />
    </template>

    <iButton @click="savePublicationConfig">
      Save
    </iButton>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationSignupAndLogin",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN,
      SECTION_PUBLICATIONS,
      signupAndLoginConfig: {
        enabled: false,
        private_publication: {
          enabled: false,
        },
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["configuration", "orgId", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/features`, label: "Features" },
      ];
    },
  },
  created() {
    this.signupAndLoginConfig = { ...this.signupAndLoginConfig, ...this.configuration.signup_and_login };
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration" }),
    async savePublicationConfig() {
      this.saveConfiguration("signup_and_login", this.signupAndLoginConfig);
    },
  },
};
</script>

<style scoped>

</style>
