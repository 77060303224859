<template>
  <PageWrapper title="Publication Post Settings" :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_FEATURE_POST_CREATION">
    <iSelect
      :model-value="ugcInterface"
      return-value
      :items="validUgcTypes"
      label="Post Creation Type"
      value-field="value"
      @update:model-value="updateUgcInterfaceConfiguration"
    />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { useConfigurationStore } from "@/stores/configuration-store";
import { PAGE_PUBLICATION_FEATURE_POST_CREATION, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationPostCreation",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_FEATURE_POST_CREATION,
      postTypes: [
        { name: "Quick Post", value: "quick_post" },
      ],
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "ugcInterface",
    ]),
    validUgcTypes() {
      return [
        { name: "None", value: "none" },
        { name: "Article", value: "article" },
        { name: "Multimedia", value: "multimedia" },
        { name: "Quick Post", value: "quickPost" },
      ];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    updateUgcInterfaceConfiguration(ugcType) {
      this.updateConfiguration({ ugcInterface: ugcType });
    },
  },
};
</script>

<style scoped>

</style>
