<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_BRANDING"
    title="Branding"
    :breadcrumbs="breadcrumbs"
    page-name="Branding"
    align="left"
    width="full"
  >
    <iRow gap="large">
      <iImageUploader
        :image="publicationImageSrc"
        change-image-text="Update your logo"
        select-image-text="Upload your logo"
        @upload-image="uploadPublicationLogoImage"
      />
      <iImageUploader
        :image="splashImageSrc"
        change-image-text="Update splash image"
        select-image-text="Upload splash image"
        @upload-image="uploadPublicationSplashImage"
      />
      <iRow>
        <iSelect
          v-model="myTheme"
          return-value
          value-field="value"
          label="Publication Theme"
          :width="500"
          :items="calculatedMappedThemes"
        />
      </iRow>
      <iRow>
        <iButton :is-loading="loadingUploadPublicationLogo" :disabled="canSave" @click.stop="submit">
          Save
        </iButton>
      </iRow>
    </iRow>
  </PageWrapper>
</template>

<script>

import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import { usePublicationStore } from "@/stores/publication-store";
import {
  PAGE_PUBLICATION_BRANDING,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useThemesStore } from "@/stores/themes-store";

export default {
  name: "PublicationBranding",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_BRANDING,
      SECTION_PUBLICATIONS,
      uploadedPublicationLogo: null,
      uploadedSplashImage: null,
      themeId: null,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId",
      "publicationId",
      "loadingUploadPublicationLogo",
      "publicationLogo",
      "publicationSplashImage",
      "publication",
      "publicationThemeId",
      "isLoadingUpdateTheme"]),
    ...mapState(useThemesStore, ["mappedThemes"]),
    calculatedMappedThemes() {
      const themes = this.mappedThemes;

      if (parseInt(this.myTheme) === 0) {
        return themes.map(theme => {
          if (theme.value === "0") {
            return {
              name: "Please select a theme",
              value: "0",
            };
          }
          return theme;
        });
      }
      return themes.filter(theme => theme.value !== "0");
    },
    canSave() {
      let disabled = true;
      if (this.themeId !== this.publicationThemeId) {
        disabled = false;
      }
      if (this.uploadedPublicationLogo) {
        disabled = false;
      }
      if (this.uploadedSplashImage) {
        disabled = false;
      }
      if (this.loadingUploadPublicationLogo || this.isLoadingUpdateTheme) {
        disabled = true;
      }
      return disabled;
    },
    myTheme: {
      get() {
        return (this.themeId ?? this.publicationThemeId).toString();
      },
      set(value) {
        this.themeId = value;
      },
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
    publicationImageSrc() {
      if (!this.publicationLogo) {
        return DEFAULT_ORG_LOGO;
      } else {
        return this.publicationLogo;
      }
    },
    splashImageSrc() {
      if (!this.publicationSplashImage) {
        return DEFAULT_SPLASH_IMAGE;
      } else {
        return this.publicationSplashImage;
      }
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["uploadPublicationLogo", "updatePublicationTheme", "uploadSplashImage"]),
    uploadPublicationLogoImage(image) {
      this.uploadedPublicationLogo = image;
    },
    uploadPublicationSplashImage(image) {
      this.uploadedSplashImage = image;
    },
    submit() {
      if (this.themeId !== null && this.themeId !== this.publicationThemeId && parseInt(this.myTheme) !== 0) {
        this.updatePublicationTheme(parseInt(this.themeId));
      }
      if (this.uploadedPublicationLogo) {
        this.uploadPublicationLogo(this.uploadedPublicationLogo).then(() => {
          this.uploadedPublicationLogo = null;
        });
      }
      if (this.uploadedSplashImage) {
        this.uploadSplashImage(this.uploadedSplashImage).then(() => {
          this.uploadedSplashImage = null;
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
