// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication-header-wrapper[data-v-0b593e75] {
  position: relative;
}
.publication-header[data-v-0b593e75] {
  position: relative;
  bottom: 30px;
}
.publication-header-logo[data-v-0b593e75] {
  border: var(--0b593e75-\\\$getStyles\\.logoBorderWidth) solid var(--0b593e75-\\\$getStyles\\.logoBorderColor);
  background: var(--0b593e75-\\\$getStyles\\.logoBackground);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/publication/PublicationProfileWrapper.vue","webpack://./PublicationProfileWrapper.vue"],"names":[],"mappings":"AAEA;EACE,kBAAA;ACDF;ADIA;EACE,kBAAA;EACA,YAAA;ACDF;ADIA;EACE,uGAAA;EACA,uDAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.publication-header-wrapper{\n  position: relative;\n}\n\n.publication-header {\n  position: relative;\n  bottom: 30px;\n}\n\n.publication-header-logo {\n  border: v-bind(\"$getStyles.logoBorderWidth\") solid v-bind(\"$getStyles.logoBorderColor\");\n  background: v-bind(\"$getStyles.logoBackground\")\n}\n",".publication-header-wrapper {\n  position: relative;\n}\n\n.publication-header {\n  position: relative;\n  bottom: 30px;\n}\n\n.publication-header-logo {\n  border: v-bind(\"$getStyles.logoBorderWidth\") solid v-bind(\"$getStyles.logoBorderColor\");\n  background: v-bind(\"$getStyles.logoBackground\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
