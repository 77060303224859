import { defineStore } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { usePaymentStore } from "@/stores/payments-store";
import { useGeneralStore } from "@/stores/general-store";
export const useConfigurationStore = defineStore("configuration-store", {
  state: () => ({
    $_configuration: {},
  }),
  getters: {
    publicationId() {
      return usePublicationStore().publicationId;
    },
    publicationStagingVersionId() {
      return usePublicationStore().publicationVersionId;
    },
    areSubscriptionsAvailable() {
      return this.canSignUp && this.availableSubscriptionPlans.length > 0;
    },
    canCreateQuickPost() {
      return this.ugcInterface == "quickPost";
    },
    isSignupAndLoginEnabled() {
      return this.canSignUp || this.isPrivatePublication;
    },
    primaryNavigation() {
      return this.$_configuration.primaryNav;
    },
    availableSubscriptionPlans() {
      return this.$_configuration.availableSubscriptionPlans;
    },
    canVoteOnPosts() {
      return this.$_configuration.canVoteOnPosts;
    },
    canLikePosts() {
      return this.$_configuration.canLikePosts;
    },
    commentModerationStrategy() {
      return this.$_configuration.commentModerationStrategy;
    },
    commentReplyDisplayStyle() {
      return this.$_configuration.commentReplyDisplayStyle;
    },
    digitalProductsVisible() {
      return this.$_configuration.digitalProductsVisible;
    },
    commentReplyNestingLimit() {
      return this.$_configuration.commentReplyNestingLimit;
    },
    canLikeComments() {
      return this.$_configuration.canLikeComments;
    },
    isPrivatePublication() {
      return this.$_configuration.isPrivatePublication;
    },
    requireAccountApproval() {
      return this.$_configuration.requireAccountApproval;
    },
    ugcInterface() {
      return this.$_configuration.ugcInterface;
    },
    commentDefaultSortingStrategy() {
      return this.$_configuration.commentDefaultSortingStrategy;
    },
    ugcModerationStrategy() {
      return this.$_configuration.ugcModerationStrategy;
    },
    canCommentOnPosts() {
      return this.$_configuration.canCommentOnPosts;
    },
    canReplyToComments() {
      return this.$_configuration.canReplyToComments;
    },
    canVoteOnComments() {
      return this.$_configuration.canVoteOnComments;
    },
    canSignUp() {
      return this.$_configuration.canSignUp;
    },
    canUserCreatePost() {
      return this.ugcInterface !== "none";
    },
  },
  actions: {
    updateConfiguration(newConfigData) {
      this.isLoadingConfig = true;
      this.apiPost(`/publication/${this.publicationId}/version/${this.publicationStagingVersionId}/update`, {
        config_data: newConfigData,
      }, data => {
        this.$_configuration = data.configuration;
        usePublicationStore().setPublicationVersionId(data.publicationVersionId);
      }, () => {
        this.isLoadingConfig = false;
      });
    },
    publishLatestStagingConfigToProduction(memo) {
      this.isLoadingConfig = true;
      this.apiPost(`/publication/${this.publicationId}/version/${this.publicationStagingVersionId}/publish`, {
        memo,
      }, data => {
        this.$_configuration = data.configuration;
        usePublicationStore().setPublicationVersionId(data.publicationVersionId);
      }, () => {
        this.isLoadingConfig = false;
      });
    },
    createSubscriptionPlan(name, description, billingFrequency, price, currencyCode, accessRevocationGracePeriod, exclusivityWindow) {
      this.isLoading = true;
      const paymentStore = usePaymentStore();
      const generalStore = useGeneralStore();

      return this.apiPost(`/subscription/publication/${this.publicationId}/version/${this.publicationStagingVersionId}`, {
        name,
        description,
        billing_frequency: billingFrequency,
        price,
        currency_code: currencyCode,
        access_revocation_grace_period_days: accessRevocationGracePeriod,
        exclusivity_window_days: exclusivityWindow,
      }, async data => {
        usePublicationStore().setPublicationId(data.publication_id);
        usePublicationStore().setPublicationVersionId(data.publication_version_id);
        usePublicationStore().setActiveSubscriptionPlans(data.activeSubscriptionPlans);
        this.$_configuration = data.configuration;
        const newPlan =
            data.activeSubscriptionPlans[data.activeSubscriptionPlans.length - 1];

        await paymentStore.createSubscriptionProductAndPrice(
          newPlan.name,
          newPlan.description,
          newPlan.price * 100,
          newPlan.currency_code,
          newPlan.billing_frequency,
          newPlan.subscription_plan_id,
          generalStore.userId,
          generalStore.orgId,
          newPlan.publication_id,
          generalStore.orgId,
          true,
        );

        return data;
      }, () => {
        this.isLoading = false;
      });
    },
    setConfiguration(configuration) {
      this.$_configuration = configuration;
    },
  },
});
