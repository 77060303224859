<template>
  <iColumn height="fill">
    <iRow height="fill" overflow="scroll">
      <ChatroomThread
        :context="context"
        :entity-id="entityId"
        :conversation-id="conversationId"
      />
    </iRow>
    <iRow height="hug">
      <NewChatroomMessage
        :context="context"
        :entity-id="entityId"
        :conversation-id="conversationId"
      />
    </iRow>
  </iColumn>
</template>

<script>
import { mapState } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import NewChatroomMessage from "@/components/conversations/NewChatroomMessage";
import ChatroomThread from "@/components/conversations/ChatroomThread";

export default {
  name: "Chatroom",
  components: {
    ChatroomThread,
    NewChatroomMessage,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState(useConversationStore, ["conversation"]),
  },
};
</script>

<style scoped lang="scss">
  .chatroom {
    margin: 10px 5px;
    width: 100%;
  }
</style>
