<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_CHAT_ROOM"
    :title="`${ publication.name } Chat Room`"
  >
    <Conversation
      layout="chatroom"
      :entity-id="publicationId"
      context="publication_chatroom"
    />
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import Conversation from "@/components/conversations/Conversation";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import { PAGE_PUBLICATION_CHAT_ROOM, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

export default {
  name: "PublicationChatRoom",
  components: {
    PageWrapper,
    Conversation,
  },
  data() {
    return {
      PAGE_PUBLICATION_CHAT_ROOM,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publication", "publicationId"]),
  },
};
</script>

<style scoped lang="scss">
.page-wrapper {
  :deep(.primary-content) {
    overflow-y: scroll;
  }
}
</style>
