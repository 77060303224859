<template>
  <PageWrapper
    :active-section="SECTION_SETTINGS"
    :active-page="PAGE_ORGANIZATION_SETTINGS"
    title="Company Settings"
    :breadcrumbs="settingsBreadcrumbs"
    page-name="Company Settings"
    align="left"
    width="full"
  >
    <iColumn
      height="fill"
      gap="extraLarge"
      overflow="scroll"
      wrap="nowrap"
    >
      <iColumn>
        <iRow>
          <iLabel>Avatar</iLabel>
        </iRow>
        <iRow vertical-align="middle">
          <AvatarImage :src="orgAvatar" size="75px" />
          <iUploader @upload-file="uploadAvatar">
            <iButton>Change Picture</iButton>
          </iUploader>
        </iRow>
      </iColumn>

      <iTextEdit
        label="Company Name"
        :width="400"
        :value="orgName"
        :is-loading="loadingChangeOrgName"
        @submit="changeOrgName"
      />

      <iTextEdit
        v-if="getOrgData.waitlist_status ==='approved'"
        label="Company Description (for your community profile)"
        text-area
        :width="400"
        :value="orgDescription"
        :is-loading="loadingUpdateOrgDescription"
        @submit="submitUpdateOrgDescription"
      />

      <iRow class="organization-user-table">
        <iDataTable
          title="Company Users"
          :items="users"
          :fields="userTableFields"
          primary-key="email_address"
          show-empty-state
          empty-string="No users found."
          :has-more="hasMoreUsers"
          :is-loading="loadingUsers"
        >
          <template #actions>
            <iRow>
              <iButton variant="secondary" @click="openModal">
                Add User
              </iButton>
            </iRow>
          </template>
          <template #td(role_id)="slotProps">
            <iDropdownMenu
              :show-dropdown="isDropdownVisible(slotProps.data.user_id, 'role')"
              position="left"
              :items="userRoleChangeTableOptions"
              @click="(value) => submitChangeRole(slotProps.data.user_id, value, slotProps.data.role_id)"
              @close="closeDropdown(slotProps.data.user_id, 'role')"
            >
              <template #trigger>
                <iButton
                  variant="tertiary"
                  :style-overrides="{paddingLeft: 'none', paddingRight: 'none'}"
                  :disabled="Number(slotProps.data.role_id) === USER_ROLE_VALUE_OWNER"
                  @click="toggleDropdown(slotProps.data.user_id, 'role')"
                >
                  {{ ALL_USER_ROLES[slotProps.data.role_id] }}
                </iButton>
              </template>
            </iDropdownMenu>
          </template>
          <template #td(status)="slotProps">
            <iStatus :status="slotProps.data.status" />
          </template>
          <template #td(actions)="slotProps">
            <iRow align="right">
              <iDropdownMenu
                v-if="Number(slotProps.data.role_id) !== USER_ROLE_VALUE_OWNER"
                :show-dropdown="isDropdownVisible(slotProps.data.user_id, 'actions')"
                position="right"
                :items="userStatusChangeTableOptions(slotProps.data)"
                @click="(value) => submitChangeStatus(slotProps.data.user_id, value)"
                @close="closeDropdown(slotProps.data.user_id, 'actions')"
              >
                <template #trigger>
                  <iIcon icon="ellipsis" @click="toggleDropdown(slotProps.data.user_id, 'actions')" />
                </template>
              </iDropdownMenu>
            </iRow>
          </template>
        </iDataTable>
      </iRow>

      <iColumn v-if="getOrgData.waitlist_status ==='approved'">
        <iHeading>Organization Profile Page Settings</iHeading>
        <iToggleSwitch
          v-model="orgVisibility.public"
          label="Public"
          @change="handleVisibilityChange('public', orgVisibility.public)"
        />

        <iToggleSwitch
          v-model="orgVisibility.private"
          label="Private"
          @change="handleVisibilityChange('private', orgVisibility.private)"
        />

        <iToggleSwitch
          v-model="orgVisibility.protected"
          label="Protected"
          @change="handleVisibilityChange('protected', orgVisibility.protected)"
        />
        <iHeading>Services Provided</iHeading>
        <iRow v-for="(service, key) in getOrgData.services_offered" :key="key">
          <iCheckbox
            :label="service.service_name"
            :name="key"
            :model-value="service.status === 'active'"
            @update:model-value="(isActive) => handleServiceChange(key, isActive)"
          />
        </iRow>
      </iColumn>
    </iColumn>

    <iModal
      v-if="isAddUserModalVisible"
      title="Add User"
      :width="600"
      @close="closeAndResetAddUserModal"
    >
      <template #body>
        <iColumn gap="large">
          <iTextInput
            v-model="userEmail"
            label="Email address:"
            name="email"
            type="email"
            width="fill"
          />
          <iTextInput
            v-model="userFirstName"
            label="First Name:"
            name="first_name"
            width="fill"
          />
          <iTextInput
            v-model="userLastName"
            label="Last Name:"
            name="last_name"
            width="fill"
          />
          <iSelect
            v-model="userRole"
            :items="userRoleSelectOptions"
            value-field="value"
            text-field="label"
            label="Role:"
            empty-string="Select Role"
            width="fill"
          />
          <iText v-if="addUserErrorMessage" variant="error">
            {{ addUserErrorMessage }}
          </iText>
        </iColumn>
      </template>
      <template #footer>
        <iRow width="fill" align="right">
          <iColumn width="hug">
            <iButton
              :disabled="invalidAddUserForm || loadingCreateUser"
              :is-loading="loadingCreateUser"
              variant="primary"
              @click="addUser"
            >
              Add User
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>
<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { useOrganizationStore } from "@/stores/organization-store";
import { useGeneralStore } from "@/stores/general-store";
import { SECTION_SETTINGS, PAGE_ORGANIZATION_SETTINGS } from "@/constants/nav-constants";
import {
  ALLOWED_ADDITIONAL_USER_ROLES,
  USER_ROLE_VALUE_OWNER,
  ALL_USER_ROLES,
  USER_CHANGE_STATUSES, USER_CHANGE_STATUS_VALUE_ENABLE, USER_CHANGE_STATUS_VALUE_DISABLE,
} from "@/constants/user-constants";
import AvatarImage from "@/components/AvatarImage.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";

export default {
  name: "Organization",
  components: {
    AvatarImage,
    PageWrapper,
  },
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_ORGANIZATION_SETTINGS,
      isEditingEmail: false,
      userEmail: "",
      userFirstName: "",
      userLastName: "",
      userRole: {},
      userTableFields: [
        { key: "email_address", label: "Email", width: "30%" },
        { key: "name", label: "Name", width: "20%" },
        { key: "role_id", label: "Role" },
        { key: "status", label: "Status", width: "90px" },
        { key: "actions", label: "" },
      ],
      dropdownStates: {},
      orgVisibility: {
        public: false,
        private: false,
        protected: false,
      },
    };
  },
  computed: {
    ...mapState(useOrganizationStore, [
      "orgNameNotEdited",
      "loadingChangeOrgName",
      "orgDescription",
      "getOrgData",
      "users",
      "loadingUsers",
      "hasMoreUsers",
      "loadingCreateUser",
      "isAddUserModalVisible",
      "orgName",
      "addUserErrorMessage",
      "loadingUpdateOrgDescription",
    ]),
    ...mapState(useGeneralStore, [
      "settingsBreadcrumbs",
      "userData",
      "orgAvatar",
    ]),
    invalidAddUserForm() {
      return !this.userEmail || !this.userFirstName || !this.userLastName || !this.userRole.value;
    },
    userRoleSelectOptions() {
      return Object.entries(ALLOWED_ADDITIONAL_USER_ROLES).map(userRole => {
        return {
          "label": userRole[1],
          "value": userRole[0],
        };
      });
    },
    userRoleChangeTableOptions() {
      return Object.entries(ALLOWED_ADDITIONAL_USER_ROLES).map(userRole => {
        return {
          name: userRole[1],
          label: userRole[1],
          actionType: "action_type_button_click",
          actionValue: userRole[0],
        };
      });
    },
    ALL_USER_ROLES() {
      return ALL_USER_ROLES;
    },
    USER_ROLE_VALUE_OWNER() {
      return USER_ROLE_VALUE_OWNER;
    },
  },
  created() {
    this.fetchOrgUsers();
    this.initializeOrgVisibility();
  },
  styleGuide: () => ({
    actionItemPadding: { "size.spacing": "standard" },
  }),
  methods: {
    userStatusChangeTableOptions(actionData) {
      let { status } = actionData;
      return USER_CHANGE_STATUSES.filter(statusAction => {
        // eslint-disable-next-line @stylistic/js/no-extra-parens
        return (status == "active" && statusAction == "Disable") || (status !== "active" && statusAction == "Enable");
      }).map(status => {
        return {
          name: status,
          label: status,
          actionType: "action_type_button_click",
          actionValue: status,
        };
      });
    },
    ...mapActions(useOrganizationStore, [
      "changeOrgName",
      "fetchOrgUsers",
      "createUserInOrg",
      "disableUser",
      "enableUser",
      "changeUserRole",
      "openAddUserModal",
      "closeAddUserModal",
      "updateOrgProfileVisibility",
      "addServiceToOrg",
      "removeServiceFromOrg",
      "updateOrgDescription",
      "uploadAvatar",
    ]),
    ...mapActions(useCollaborationStore, [
      "openInviteToPublicationModel",
    ]),
    openModal() {
      this.openAddUserModal();
      this.userRole = {};
    },
    addUser() {
      if (this.userEmail && this.userRole !== {}) {
        this.createUserInOrg(this.userEmail, this.userRole.value, this.userFirstName, this.userLastName);
      }
    },
    loadMore() {
      this.fetchOrgUsers();
    },
    submitChangeStatus(userId, newStatus) {
      if (newStatus === USER_CHANGE_STATUS_VALUE_DISABLE) {
        this.submitDisableUser(userId);
      } else if (newStatus === USER_CHANGE_STATUS_VALUE_ENABLE) {
        this.submitEnableUser(userId);
      }
    },
    submitUpdateOrgDescription(orgDescription) {
      this.updateOrgDescription(orgDescription);
    },
    submitDisableUser(userId) {
      this.disableUser(userId, this.getOrgData.org_id);
    },
    submitEnableUser(userId) {
      this.enableUser(userId, this.getOrgData.org_id);
    },
    submitChangeRole(userId, newRoleId, roleId) {
      this.changeUserRole(userId, this.getOrgData.org_id, Number(roleId), newRoleId);
    },
    toggleDropdown(id, type) {
      this.dropdownStates = { ...this.dropdownStates, [`${id}-${type}`]: !this.dropdownStates[`${id}-${type}`] };
    },
    closeDropdown(id, type) {
      this.dropdownStates = { ...this.dropdownStates, [`${id}-${type}`]: false };
    },
    isDropdownVisible(id, type) {
      return !!this.dropdownStates[`${id}-${type}`];
    },
    handleVisibilityChange(setting) {
      const updatedVisibility = {
        public: false,
        private: false,
        protected: false,
      };
      updatedVisibility[setting] = true;
      this.orgVisibility = updatedVisibility;
      this.updateOrgProfileVisibility(setting);
    },
    handleServiceChange(serviceKey, isActive) {
      const service = this.getOrgData.services_offered[serviceKey];
      if (isActive) {
        this.addServiceToOrg(service.service_id);
      } else {
        this.removeServiceFromOrg(service.service_id);
      }
    },
    initializeOrgVisibility() {
      const visibility = this.getOrgData.profile_page_visibility;
      this.orgVisibility = {
        public: visibility === "public",
        private: visibility === "private",
        protected: visibility === "protected",
      };
    },
    closeAndResetAddUserModal() {
      this.userRole = {};
      this.userEmail = "";
      this.userFirstName = "";
      this.userLastName = "";
      this.closeAddUserModal();
    },
  },
};
</script>
<style scoped>
.dropdown-content {
  padding: v-bind("$getStyles.actionItemPadding");
}

.organization-user-table {
  min-width: 700px;
}
</style>
