// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container[data-v-03305a12] {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
.nav-bar[data-v-03305a12] {
  width: 100%;
  padding: 0 40px;
}
@media (max-width: 768px) {
.nav-bar[data-v-03305a12] {
    padding: 0 20px;
}
.nav-bar .nav-fill[data-v-03305a12] {
    display: none;
}
.nav-bar .button-container[data-v-03305a12] {
    gap: 0;
}
.nav-bar[data-v-03305a12] .i-button {
    width: min-content;
    white-space: nowrap;
}
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/splash/SplashWrapper.vue","webpack://./SplashWrapper.vue","webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,uBAAA;EACA,MAAA;EACA,UAAA;ACDF;ADGA;EACE,WAAA;EACA,eAAA;ACAF;ACTE;AFaA;IACE,eAAA;ACAF;ADEE;IACE,aAAA;ACAJ;ADGE;IACE,MAAA;ACDJ;ADIE;IACE,kBAAA;IACA,mBAAA;ACFJ;AACF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.nav-container {\n  position: sticky;\n  background-color: white;\n  top: 0;\n  z-index: 1;\n}\n.nav-bar {\n  width: 100%;\n  padding: 0 40px;\n}\n\n@include phone {\n  .nav-bar {\n    padding: 0 20px;\n\n    .nav-fill {\n      display: none;\n    }\n\n    .button-container {\n      gap: 0;\n    }\n\n    :deep(.i-button) {\n      width: min-content;\n      white-space: nowrap;\n    }\n  }\n}\n",".nav-container {\n  position: sticky;\n  background-color: white;\n  top: 0;\n  z-index: 1;\n}\n\n.nav-bar {\n  width: 100%;\n  padding: 0 40px;\n}\n\n@media (max-width: 768px) {\n  .nav-bar {\n    padding: 0 20px;\n  }\n  .nav-bar .nav-fill {\n    display: none;\n  }\n  .nav-bar .button-container {\n    gap: 0;\n  }\n  .nav-bar :deep(.i-button) {\n    width: min-content;\n    white-space: nowrap;\n  }\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
