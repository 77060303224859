<template>
  <PageWrapper
    :active-page="PAGE_MY_PUBLICATIONS"
    :active-section="SECTION_PUBLICATIONS"
    :breadcrumbs="publicationBreadCrumbs"
    align="left"
    page-name="My Publications"
    width="full"
  >
    <iColumn v-if="hasActiveInvitations" gap="standard">
      <iSubHeading> Invites</iSubHeading>
      <iRow width="fill" wrap="wrap">
        <PublicationCollaboratorCard
          v-for="invitation in activeCollaboratorInvitations"
          :key="invitation.publication_applicant_id"
          :collaborator="mapInvitationToCollaborator(invitation)"
          show-actions
          @accept="handleAcceptInvitation(invitation.publication_applicant_id)"
          @deny="handleDenyInvitation(invitation.publication_applicant_id)"
        />
      </iRow>
    </iColumn>

    <iRow vertical-align="middle">
      <iSubHeading> My Publications</iSubHeading>
      <iLink :href="`/publication/org/${orgId}/create`">
        <iButton variant="secondary">
          Create New Publication
        </iButton>
      </iLink>
    </iRow>
    <iColumn v-if="publications?.length === 0">
      <iSpace :height="1" />
      <iText variant="subtle">
        No Publications Found. Create a new publication to get started.
      </iText>
    </iColumn>
    <SmartFeed :pinia-store="usePublicationFeedStore" default-layout="small-card" />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import PublicationCollaboratorCard from "@/components/publication/PublicationCollaboratorsCard.vue";
import {
  PAGE_MY_PUBLICATIONS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";

import { useCollaborationStore } from "@/stores/collaboration-store";
import { usePublicationStore } from "@/stores/publication-store";
import SmartFeed from "@/components/smart-feed/SmartFeed";
import { usePublicationFeedStore } from "@/stores/smart-feed/feed/publication-feed-store";

export default {
  name: "ViewPublicationList",
  components: { SmartFeed, PublicationCollaboratorCard, PageWrapper },
  data() {
    return {
      PAGE_MY_PUBLICATIONS,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId"]),
    ...mapState(usePublicationStore, { publications: "myPublications" }),
    ...mapState(useCollaborationStore, ["activeCollaboratorInvitations"]),
    publicationBreadCrumbs() {
      return [{ url: "/", label: "Home" }];
    },
    hasActiveInvitations() {
      return this.activeCollaboratorInvitations.length > 0;
    },
  },
  methods: {
    usePublicationFeedStore,
    ...mapActions(useCollaborationStore, [
      "acceptCollaboratorOrgInvitation",
      "declineCollaboratorInvitation",
    ]),
    async handleAcceptInvitation(applicantId) {
      const invitation = this.getCollaboratorInvitation(applicantId);
      await this.acceptCollaboratorOrgInvitation(
        invitation.publication_id,
        invitation.org_id,
      );
    },
    async handleDenyInvitation(applicantId) {
      const invitation = this.getCollaboratorInvitation(applicantId);
      await this.declineCollaboratorInvitation(
        invitation.publication_id,
        invitation.org_id,
      );
    },
    getCollaboratorInvitation(applicantId) {
      return (
        this.activeCollaboratorInvitations.find(
          invitation => invitation.publication_applicant_id === applicantId,
        ) || null
      );
    },
    mapInvitationToCollaborator(invitation) {
      return {
        id: invitation.publication_applicant_id,
        name: invitation.publication.name,
        role: "Invitee",
        status: invitation.status,
        email: invitation.email,
        avatar: invitation.avatar || "",
        orgId: invitation.org_id,
      };
    },
  },
};
</script>
