<template>
  <iStyledCard
    :splash-image="entity.splashUrl"
    :title="entity.name"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/community/org/${entity.orgId}/profile`"
  >
    <template #title>
      <iRow vertical-align="middle" wrap="nowrap">
        <iImageContainer
          object-fit="cover"
          :height="35"
          :width="35"
          shape="circle"
          :src="entity.logoUrl"
        />
        <iSubHeading font-weight="dark">
          {{ entity.name }}
        </iSubHeading>
      </iRow>
    </template>
  </iStyledCard>
</template>

<script>
export default {
  name: "OrgCardSmall",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
  }),
};
</script>

<style scoped lang="scss">
.org-card {
  border: v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.org-details {
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
}
</style>
