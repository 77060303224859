// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-preview-card[data-v-2df589aa] {
  flex: 1 1 30%;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  text-overflow: ellipsis;
  border-radius: 5px;
  max-width: 350px;
  min-width: 350px;
}
.post-preview-image[data-v-2df589aa] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  overflow: hidden;
}
.post-preview-title[data-v-2df589aa] {
  margin-bottom: 0;
}
.post-preview-status[data-v-2df589aa] {
  padding: 8px;
  border: 1px solid #E7E5EB;
  border-radius: 4px;
}
.post-preview-details[data-v-2df589aa] {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
}
.post-preview-content[data-v-2df589aa] {
  text-overflow: ellipsis;
  text-wrap: none;
  max-height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/PostPreview.vue","webpack://./PostPreview.vue"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,yCAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;ACFJ;ADME;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,WAAA;EACA,gBAAA;ACLJ;ADQE;EACE,gBAAA;ACNJ;ADSE;EACE,YAAA;EACA,yBAAA;EACA,kBAAA;ACPJ;ADUE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;ACRJ;ADWE;EACE,uBAAA;EACA,eAAA;EACA,iBAAA;ACTJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.post-preview {\n  &-card {\n    flex: 1 1 30%;\n    box-sizing: border-box;\n    overflow: hidden;\n    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);\n    text-overflow: ellipsis;\n    border-radius: 5px;\n    max-width: 350px;\n    min-width: 350px;\n\n  }\n\n  &-image {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    height: 50%;\n    overflow: hidden;\n  }\n\n  &-title {\n    margin-bottom: 0;\n  }\n\n  &-status {\n    padding: 8px;\n    border: 1px solid #E7E5EB;\n    border-radius: 4px;\n  }\n\n  &-details {\n    width: 100%;\n    padding: 12px;\n    box-sizing: border-box;\n  }\n\n  &-content {\n    text-overflow: ellipsis;\n    text-wrap: none;\n    max-height: 300px;\n  }\n}\n",".post-preview-card {\n  flex: 1 1 30%;\n  box-sizing: border-box;\n  overflow: hidden;\n  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);\n  text-overflow: ellipsis;\n  border-radius: 5px;\n  max-width: 350px;\n  min-width: 350px;\n}\n.post-preview-image {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50%;\n  overflow: hidden;\n}\n.post-preview-title {\n  margin-bottom: 0;\n}\n.post-preview-status {\n  padding: 8px;\n  border: 1px solid #E7E5EB;\n  border-radius: 4px;\n}\n.post-preview-details {\n  width: 100%;\n  padding: 12px;\n  box-sizing: border-box;\n}\n.post-preview-content {\n  text-overflow: ellipsis;\n  text-wrap: none;\n  max-height: 300px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
