<template>
  <iStyledCard
    :splash-image="publicationLogo"
    :title="publication.name"
    :badge="snakeToTitle(publication.status)"
    :subtitle="publication.domain"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/community/publications/${publication.publication_id}/profile`"
  >
    <template #footer>
      <iLink width="fill" :href="`/community/publications/${publication.publication_id}/profile`">
        <iButton width="fill" variant="secondary">
          View Details
        </iButton>
      </iLink>
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_PUBLICATION_IMAGE } from "@/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";
export default {
  name: "PublicationCard",
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewDropdown: false,
      DEFAULT_PUBLICATION_IMAGE,
    };
  },
  computed: {
    viewMenuItems() {
      return [
        {
          name: "View Live",
          actionValue: `/my-publication/${this.publication.publication_id}`,
          label: "View Live",
        },
        {
          name: "View Staging",
          actionValue: `/my-publication/staging/${this.publication.publication_id}`,
          label: "View Staging",
        },
      ];
    },
    publicationLogo() {
      if (!this.publication.publication_logo) {
        return this.DEFAULT_PUBLICATION_IMAGE;
      }
      return this.publication.publication_logo;
    },
  },
  methods: {
    snakeToTitle,
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
  }),
};

</script>
<style scoped lang="scss">
.publication-card {
  border: v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details {
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
}
</style>
