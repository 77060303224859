<template>
  <iColumn gap="small">
    <iText variant="standard" font-size="extraLarge">
      Team
    </iText>
    <OrgTeamCard
      v-for="user in activeUsers"
      :key="user.user_id"
      :name="user.name"
      :avatar-url="user.avatar_url"
    />
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import OrgTeamCard from "@/components/directory/OrgTeamCard.vue";

export default {
  name: "OrgTeam",
  components: { OrgTeamCard },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(useOrganizationStore, [
      "activeUsers",
    ]),
  },
  created() {
    this.fetchActiveOrgUsers(this.orgId);
  },
  methods: {
    ...mapActions(useOrganizationStore, [
      "fetchActiveOrgUsers",
    ]),
  },
};
</script>
