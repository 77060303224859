<template>
  <iRow gap="large">
    <AvatarImage :src="avatarUrl" size="45px" />
    <iText variant="standard" font-size="large">
      {{ name }}
    </iText>
  </iRow>
</template>

<script>
import AvatarImage from "@/components/AvatarImage.vue";

export default {
  name: "OrgTeamCard",
  components: { AvatarImage },
  props: {
    name: {
      type: String,
      required: true,
    },
    avatarUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

