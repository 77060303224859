<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="publication.name"
    :title="publication.name"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Overview"
    align="left"
    width="full"
  >
    <iColumn>
      <!-- <iText>publication ID - {{ publicationId }}</iText> -->
      <iRow>
        <iImageContainer :src="publicationLogo" :width="300" objject-fit="cover" />
      </iRow>
      <!-- <iText>domain {{ publication.domain }}</iText> -->
      <!-- <iText>url slug - {{ publication.url_slug }}</iText> -->
      <iLink :href="`/publication/${publicationId}/posts`">
        <iButton variant="secondary">
          View Posts
        </iButton>
      </iLink>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { DEFAULT_PUBLICATION_IMAGE } from "@/constants/publication-constants";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_DASHBOARD,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "ViewPublicationDetails",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_DASHBOARD,
      SECTION_PUBLICATIONS,
      DEFAULT_PUBLICATION_IMAGE,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publication", "publicationId", "orgId"]),
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
    publicationLogo() {
      if (!this.publication.publication_logo) {
        return this.DEFAULT_PUBLICATION_IMAGE;
      }
      return this.publication.publication_logo;
    },
  },
};
</script>
