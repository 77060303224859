<template>
  <iColumn :width="600">
    <iText>{{ entity.affiliateLink }}</iText>
    <iText>{{ entity.advertiserName }}</iText>
  </iColumn>
</template>
<script>

export default {
  name: "AffiliateLinkEntityCardLarge",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
};
</script>

