import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";
import { $axios } from "@bloglovin/vue-framework";

export const useOrganizationStore = defineStore("organization-store", {
  state: () => ({
    isLoading: false,
    loadingChangeOrgName: false,
    loadingUpdateOrgDescription: false,
    loadingUsers: false,
    loadingOrgServiceOffered: false,
    loadingPublicOrgs: false,
    loadingAdvertiserOrgs: false,
    loadingCreatorOrgs: false,
    loadingCreateUser: false,
    loadingUpdateOrgVisibility: false,
    serviceOfferedError: "",
    editOrgName: "",
    editOrgNameError: "",
    users: [],
    publicOrgs: [],
    advertiserOrgs: [],
    creatorOrgs: [],
    errorMessage: "",
    lastUserId: 0,
    lastPublicOrgId: 0,
    lastAdvertiserOrgId: 0,
    lastCreatorOrgId: 0,
    signupErrorMessage: "",
    isAddUserModalVisible: false,
    hasMorePublicOrgs: false,
    hasMoreAdvertiserOrgs: false,
    hasMoreCreatorOrgs: false,
    addUserErrorMessage: "",
    activeUsers: [],
    orgProfileData: [],
    assertOrgExists: [],
  }),

  getters: {
    usersApiV1Url() {
      return useGeneralStore().getLink(links.usersApiV1);
    },
    getOrgData() {
      return useGeneralStore().orgData;
    },
    orgName() {
      return this.getOrgData.name;
    },
    orgDescription() {
      return this.getOrgData.org_description;
    },
    orgNameNotEdited() {
      return this.orgName === this.editOrgName;
    },
    userId() {
      return useGeneralStore().userId;
    },
    hasMoreUsers() {
      return this.lastUserId !== -1;
    },
    getUserIndexById: state => id => {
      return state.users.findIndex(el => el.user_id === id);
    },
    hasPublicationCapability() {
      return this.orgProfileData.capabilities[3].enabled;
    },
    hasAdvertiserCapability() {
      return this.orgProfileData.capabilities[2].enabled || this.orgProfileData.capabilities[1].enabled;
    },
  },
  actions: {
    uploadSplash(file) {
      return this.uploadImage(file, "splash", `/org/${this.getOrgData.org_id}/splash/upload`);
    },
    uploadLogo(file) {
      return this.uploadImage(file, "logo", `/org/${this.getOrgData.org_id}/logo/upload`);
    },
    uploadImage(file, varName, url) {
      let formData = new FormData();
      formData.append(varName, file);
      $axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        useGeneralStore().setOrgData(response.data.orgData);
      });
    },
    changeOrgName(orgName) {
      if (this.loadingChangeOrgName) {
        return;
      }

      this.loadingChangeOrgName = true;
      this.apiPost(
        `${this.usersApiV1Url}/org/${this.getOrgData.org_id}/rename`,
        {
          user_id: this.userId,
          org_name: orgName,
        },
        () => {
          useGeneralStore().setOrgName(orgName);
          this.loadingChangeOrgName = false;
        },
        () => {
          this.loadingChangeOrgName = false;
        },
      );
    },

    fetchOrgUsers() {
      if (this.lastUserId === -1 || this.loadingUsers) {
        return;
      }
      this.loadingUsers = true;
      this.apiGet(
        `${this.usersApiV1Url}/org/${this.getOrgData.org_id}/users`,
        {
          last_id: this.lastUserId,
        },
        response => {
          const newUsers = response.users.map(user => ({
            user_id: user.user_id,
            email_address: user.email_address,
            name: `${user.first_name} ${user.last_name}`,
            role_id: user.role_id,
            status: user.status,
          }));
          this.users = [...this.users, ...newUsers];
          this.lastUserId = newUsers.length ? newUsers.at(-1).user_id : -1;
          this.loadingUsers = false;
        },
      );
    },
    fetchActiveOrgUsers(orgId) {
      if (this.lastUserId === -1 || this.loadingUsers) {
        return;
      }
      this.loadingUsers = true;
      this.apiGet(
        `/org/${orgId}/users/active-with-avatars`,
        {
          last_id: this.lastUserId,
        },
        response => {
          const newUsers = response.users.map(user => ({
            user_id: user.user_id,
            email_address: user.email_address,
            name: `${user.first_name} ${user.last_name}`,
            role_id: user.role_id,
            status: user.status,
            avatar_url: user.avatar_url ?? "",
          }));

          this.users = [...this.users, ...newUsers];
          this.activeUsers = this.users;
          this.lastUserId = newUsers.length ? newUsers.at(-1).user_id : -1;

          this.loadingUsers = false;
        },
        () => {
          this.errorMessage = "Error fetching active org users";
        },
      );
    },
    fetchPublicOrgs() {
      if (!this.hasMorePublicOrgs || this.loadingPublicOrgs) {
        return;
      }

      this.loadingPublicOrgs = true;
      this.apiGet(
        "/org/orgs/public",
        {
          last_id: this.lastPublicOrgId,
        },
        data => {
          this.publicOrgs = [...this.publicOrgs, ...data.orgs];
          this.lastPublicOrgId = data.lastId;
          this.hasMorePublicOrgs = data.hasMore;
        },
        () => {},
        () => {
          this.loadingPublicOrgs = false;
        },
      );
      this.loadingPublicOrgs = false;
    },
    fetchAdvertiserOrgs() {
      if (!this.hasMoreAdvertiserOrgs || this.loadingAdvertiserOrgs) {
        return;
      }

      this.loadingAdvertiserOrgs = true;
      this.apiGet(
        "/org/orgs/advertisers",
        {
          last_id: this.lastAdvertiserOrgId,
        },
        data => {
          this.advertiserOrgs = [...this.advertiserOrgs, ...data.orgs];
          this.lastAdvertiserOrgId = data.lastId;
          this.hasMoreAdvertiserOrgs = data.hasMore;
        },
        () => {},
        () => {
          this.loadingAdvertiserOrgs = false;
        },
      );
      this.loadingAdvertiserOrgs = false;
    },
    fetchCreatorOrgs() {
      if (!this.hasMoreCreatorOrgs || this.loadingCreatorOrgs) {
        return;
      }

      this.loadingCreatorOrgs = true;
      this.apiGet(
        "/org/orgs/public",
        {
          last_id: this.lastCreatorOrgId,
        },
        data => {
          this.creatorOrgs = [...this.creatorOrgs, ...data.orgs];
          this.lastCreatorOrgId = data.lastId;
          this.hasMoreCreatorOrgs = data.hasMore;
        },
        () => {},
        () => {
          this.loadingCreatorOrgs = false;
        },
      );
      this.loadingCreatorOrgs = false;
    },
    async assertOrgExistsByEmail(email) {
      await this.apiGet(
        "/org/exists-by-email",
        {
          email: email,
        },
        response => {
          this.assertOrgExists = response;
        },
      );
    },
    async createUserInOrg(email, roleId, firstName, lastName) {
      this.loadingCreateUser = true;
      this.addUserErrorMessage = "";

      await this.apiPost(
        `${this.usersApiV1Url}/org/${this.getOrgData.org_id}/invite`,
        {
          org_id: this.getOrgData.org_id,
          email: email,
          role_id: roleId,
          first_name: firstName,
          last_name: lastName,
        },
        () => {
          this.closeAddUserModal();
          this.fetchOrgUsers();
          this.loadingCreateUser = false;
        },
        error => {
          this.addUserErrorMessage = parseDatabaseError(error);
          this.loadingCreateUser = false;
        },
      );
    },
    updateOrgProfileVisibility(visibility) {
      this.loadingUpdateOrgVisibility = true;
      this.apiPost(
        `/org/${this.getOrgData.org_id}/profile/visibility/update`,
        {
          "profile_visibility": visibility,
        },
        () => {
          this.loadingUpdateOrgVisibility = false;
        },
        () => {
          this.loadingUpdateOrgVisibility = false;
        },
      );
    },
    updateOrgDescription(orgDescription) {
      this.loadingUpdateOrgDescription = true;
      this.apiPost(
        `/org/${this.getOrgData.org_id}/profile/description/update`,
        {
          "org_description": orgDescription,
        },
        () => {
          this.orgDescription = orgDescription;
          this.loadingUpdateOrgDescription = false;
        },
        () => {
          this.loadingUpdateOrgDescription = false;
        },
      );
    },
    disableUser(userId) {
      this.isLoading = true;
      this.apiPost(
        `${this.usersApiV1Url}/org/${this.getOrgData.org_id}/user/${userId}/disable`,
        {},
        response => {
          if (response.user) {
            const status = response.user.status;
            this.updateUserStatus(userId, status);
          } else {
            this.signupErrorMessage =
                            "Something unexpected went wrong during signup";
          }
        },
        error => {
          this.signupErrorMessage =
                        error.message || "Something unexpected went wrong during signup";
        },
      );
    },
    enableUser(userId) {
      this.apiPost(
        `${this.usersApiV1Url}/org/${this.getOrgData.org_id}/user/${userId}/enable`,
        {},
        response => {
          if (response.user) {
            const status = response.user.status;
            this.updateUserStatus(userId, status);
          } else {
            this.signupErrorMessage =
                            "Something unexpected went wrong during signup";
          }
        },
        error => {
          this.signupErrorMessage =
                        error.message || "Something unexpected went wrong during signup";
        },
      );
    },

    changeUserRole(userId, orgId, roleId, newRoleId) {
      this.apiPost(
        `${this.usersApiV1Url}/org/${orgId}/role/change/${userId}`,
        {
          role_id: roleId,
          new_role_id: newRoleId,
        },
        response => {
          if (response.role_id) {
            const updatedRoleId = response.role_id;
            this.updateUserRole(userId, updatedRoleId);
          } else {
            this.signupErrorMessage =
                            "Something unexpected went wrong during signup";
          }
        },
        error => {
          this.signupErrorMessage =
                        error.message || "Something unexpected went wrong during signup";
        },
      );
    },

    addServiceToOrg(serviceId) {
      this.loadingOrgServiceOffered = true;
      this.apiPost(
        `/org/${this.getOrgData.org_id}/service/add`,
        {
          service_id: serviceId,
        },
        () => {
          this.updateServiceStatus(serviceId, "active");
          this.loadingOrgServiceOffered = false;
        },
        () => {
          this.serviceOfferedError = "Unable to update offered service";
          this.loadingOrgServiceOffered = false;
        },
      );
    },

    removeServiceFromOrg(serviceId) {
      this.loadingOrgServiceOffered = true;
      this.apiPost(
        `/org/${this.getOrgData.org_id}/service/remove`,
        {
          service_id: serviceId,
        },
        () => {
          this.updateServiceStatus(serviceId, "inactive");
          this.loadingOrgServiceOffered = false;
        },
        () => {
          this.serviceOfferedError = "Unable to update offered service";
          this.loadingOrgServiceOffered = false;
        },
      );
    },

    updateServiceStatus(serviceId, status) {
      const serviceKey = Object.keys(this.getOrgData.services_offered).find(key => {
        return this.getOrgData.services_offered[key].service_id === serviceId;
      });

      if (serviceKey) {
        this.getOrgData.services_offered[serviceKey].status = status;
      }
    },

    updateUserStatus(userId, status) {
      const userIndex = this.getUserIndexById(userId);
      if (userIndex !== -1) {
        this.users[userIndex].status = status;
      }
    },

    updateUserRole(userId, roleId) {
      const userIndex = this.getUserIndexById(userId);
      if (userIndex !== -1) {
        this.users[userIndex].role_id = roleId;
      }
    },
    closeAddUserModal() {
      this.addUserErrorMessage = "";
      this.isAddUserModalVisible = false;
    },
    openAddUserModal() {
      this.addUserErrorMessage = "";
      this.isAddUserModalVisible = true;
    },
  },
});

function parseDatabaseError(error) {
  if (error.response && error.response.data && error.response.data.message) {
    const message = error.response.data.message;
    if (message.includes("Duplicate entry")) {
      return "Cannot create user as they already exist within the organization.";
    }
  }
  return "An unexpected error occurred. Please try again later.";
}
