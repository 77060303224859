// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
.waitlist-card.i-card[data-v-f79f65be] {
    width: 100%;
    min-width: unset;
    max-width: unset;
}
}
.waitlist-card.i-card[data-v-f79f65be]:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss","webpack://./src/Web/scripts/components/waitlist/WaitlistCard.vue","webpack://./WaitlistCard.vue"],"names":[],"mappings":"AACE;ACEA;IAEI,WAAA;IACA,gBAAA;IACA,gBAAA;ACFJ;AACF;ADII;EACE,eAAA;ACFN","sourcesContent":["@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n","@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.waitlist-card {\n  &.i-card {\n    @include phone {\n      width: 100%;\n      min-width: unset;\n      max-width: unset;\n    }\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n}\n","@media (max-width: 768px) {\n  .waitlist-card.i-card {\n    width: 100%;\n    min-width: unset;\n    max-width: unset;\n  }\n}\n.waitlist-card.i-card:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
