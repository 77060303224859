// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo[data-v-384bd40e] {
  height: var(--384bd40e-height);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.logo .image[data-v-384bd40e] {
  height: 100%;
  object-fit: contain;
}
.logo.full[data-v-384bd40e] {
  display: inline;
  margin-bottom: -4px;
  padding-left: 8px;
}
.logo.tablet[data-v-384bd40e] {
  height: 50px;
  display: none;
}
@media (max-width: 1365px) {
.logo.tablet[data-v-384bd40e] {
    display: inline;
}
.logo.full[data-v-384bd40e] {
    display: none;
}
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/Logo.vue","webpack://./Logo.vue","webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAGA;EACE,8BAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;ACFF;ADIE;EACE,YAAA;EACA,mBAAA;ACFJ;ADKE;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;ACHJ;ADKE;EACE,YAAA;EACA,aAAA;ACHJ;ACXE;AFkBE;IACE,eAAA;ACJJ;ADME;IACE,aAAA;ACJJ;AACF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n\n.logo {\n  height: v-bind(height);\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n\n  .image {\n    height: 100%;\n    object-fit: contain;\n  }\n\n  &.full {\n    display: inline;\n    margin-bottom: -4px;\n    padding-left: 8px;\n  }\n  &.tablet {\n    height: 50px;\n    display: none;\n  }\n\n  @include tablet {\n    &.tablet {\n      display: inline;\n    }\n    &.full {\n      display: none;\n    }\n  }\n}\n",".logo {\n  height: v-bind(height);\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n.logo .image {\n  height: 100%;\n  object-fit: contain;\n}\n.logo.full {\n  display: inline;\n  margin-bottom: -4px;\n  padding-left: 8px;\n}\n.logo.tablet {\n  height: 50px;\n  display: none;\n}\n@media (max-width: 1365px) {\n  .logo.tablet {\n    display: inline;\n  }\n  .logo.full {\n    display: none;\n  }\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
