<template>
  <SplashWrapper>
    <iRow>
      <iColumn width="fill" :gap="100" align="center">
        <iColumn
          class="hero"
          width="fill"
          :gap="40"
          align="center"
        >
          <iRow class="marketing-heading" align="center">
            <iDisplayText align="center">
              Your Brand, Your Voice:
              Join the Evolution of Media
            </iDisplayText>
          </iRow>

          <iRow>
            <iCarousel :images="images" gap="large" />
          </iRow>

          <iRow class="marketing-text" align="center">
            <iHeading align="center">
              Welcome to a place where your <strong>creativity shines</strong> and <strong>personal brand</strong> thrives.
              Turn your <strong>passions into success</strong>, connect deeply with your audience,
              and grow your business while <strong>staying true to who you are.</strong>
            </iHeading>
          </iRow>
        </iColumn>
        <iRow align="center" class="left-right">
          <SplashColumnContainer :width="1280">
            <template #left>
              <iImageContainer
                src="/images/splash/splash-container-1.png"
                alt="placeholder"
                object-fit="cover"
              />
            </template>
            <template #right>
              <SplashTextColumn
                heading="Craft your unique online platform"
              >
                Create <strong>your own digital space</strong> to share your ideas, interests, and hobbies.
                Design a website, a newsletter, you name it - a publication that reflects your
                <strong>unique personal brand,</strong> distinct from corporate identities. Be yourself and let your
                creativity shine through <strong>various publications and a mix of verticals</strong> that
                showcase your diverse talents.
              </SplashTextColumn>
            </template>
          </SplashColumnContainer>
        </iRow>

        <iRow align="center" class="left-right">
          <SplashColumnContainer :width="1280" invert-on-wrap>
            <template #left>
              <SplashTextColumn
                heading="Amplify your brand"
              >
                Reach a wider audience and <strong>make your mark</strong> with content that resonates.
                We provide the tools to help you create and distribute engaging, commercially successful content
                that <strong>highlights your skills and talents.</strong> Empower your personal brand and influence
                people in a way <strong>that truly reflects who you are.</strong>
              </SplashTextColumn>
            </template>
            <template #right>
              <iImageContainer
                src="/images/splash/splash-container-2.png"
                alt="placeholder"
                object-fit="cover"
              />
            </template>
          </SplashColumnContainer>
        </iRow>

        <SplashJoinWaitingListContainer />

        <iRow align="center" wrap="wrap" class="left-right">
          <SplashColumnContainer :width="1280">
            <template #left>
              <iImageContainer
                src="/images/splash/splash-container-3.png"
                class="mirror"
                alt="placeholder"
                object-fit="cover"
              />
            </template>
            <template #right>
              <SplashTextColumn
                heading="Find your people"
              >
                <strong>Connect with other creators</strong> who share your passions and vision.
                Our platform fosters meaningful conversations and collaborations,
                allowing you to <strong>pull your community together.</strong> Build <strong>a network of
                  support and inspiration,</strong> and <strong>grow alongside other talented individuals.</strong>
              </SplashTextColumn>
            </template>
          </SplashColumnContainer>
        </iRow>

        <iRow align="center" wrap="wrap" class="left-right">
          <SplashColumnContainer :width="1280" invert-on-wrap>
            <template #left>
              <SplashTextColumn
                heading="Grow a business as unique as you are"
              >
                <strong>Take control</strong> of your entrepreneurial journey with a business that
                <strong>isn’t confined to a single niche.</strong> Our platform supports your ambition to run and grow
                an <strong>autonomous business,</strong> offering the flexibility to explore various verticals.
                Harness your <strong>creativity and skills to influence and inspire,</strong>
                while building a sustainable and thriving brand.
              </SplashTextColumn>
            </template>
            <template #right>
              <iImageContainer
                src="/images/splash/splash-container-4.png"
                alt="placeholder"
                object-fit="cover"
              />
            </template>
          </SplashColumnContainer>
        </iRow>

        <!--        <iRow v-if="false" align="center">-->
        <!--          <SplashColumnContainer vertical-align="top" :width="1300">-->
        <!--            <template #left>-->
        <!--              <iRow height="fill" vertical-align="top" :width="250">-->
        <!--                <iHeading font-size="extraExtraLarge">-->
        <!--                  Your questions, answered-->
        <!--                </iHeading>-->
        <!--              </iRow>-->
        <!--            </template>-->
        <!--            <template #right>-->
        <!--              <iRow :width="616">-->
        <!--                <iCollapsablePanel>-->
        <!--                  <template #header>-->
        <!--                    <iHeading font-size="large">-->
        <!--                      What is a personal brand?-->
        <!--                    </iHeading>-->
        <!--                  </template>-->
        <!--                  <template #content>-->
        <!--                    <iText variant="subtle" font-size="large">-->
        <!--                      A personal brand is a widely-recognized and largely-uniform perception or impression of an individual-->
        <!--                      based on their experience, expertise, competencies, actions and/or achievements within a community,-->
        <!--                      industry, or the marketplace at large.-->
        <!--                    </iText>-->
        <!--                  </template>-->
        <!--                </iCollapsablePanel>-->
        <!--                <iDivider :thickness="1" />-->
        <!--                <iCollapsablePanel>-->
        <!--                  <template #header>-->
        <!--                    <iHeading font-size="large">-->
        <!--                      What is a personal brand?-->
        <!--                    </iHeading>-->
        <!--                  </template>-->
        <!--                  <template #content>-->
        <!--                    <iText variant="subtle" font-size="large">-->
        <!--                      A personal brand is a widely-recognized and largely-uniform perception or impression of an individual-->
        <!--                      based on their experience, expertise, competencies, actions and/or achievements within a community,-->
        <!--                      industry, or the marketplace at large.-->
        <!--                    </iText>-->
        <!--                  </template>-->
        <!--                </iCollapsablePanel>-->
        <!--                <iDivider :thickness="1" />-->
        <!--                <iCollapsablePanel>-->
        <!--                  <template #header>-->
        <!--                    <iHeading font-size="large">-->
        <!--                      What is a personal brand?-->
        <!--                    </iHeading>-->
        <!--                  </template>-->
        <!--                  <template #content>-->
        <!--                    <iText variant="subtle" font-size="large">-->
        <!--                      A personal brand is a widely-recognized and largely-uniform perception or impression of an individual-->
        <!--                      based on their experience, expertise, competencies, actions and/or achievements within a community,-->
        <!--                      industry, or the marketplace at large.-->
        <!--                    </iText>-->
        <!--                  </template>-->
        <!--                </iCollapsablePanel>-->
        <!--                <iDivider :thickness="1" />-->
        <!--                <iCollapsablePanel>-->
        <!--                  <template #header>-->
        <!--                    <iHeading font-size="large">-->
        <!--                      What is a personal brand?-->
        <!--                    </iHeading>-->
        <!--                  </template>-->
        <!--                  <template #content>-->
        <!--                    <iText variant="subtle" font-size="large">-->
        <!--                      A personal brand is a widely-recognized and largely-uniform perception or impression of an individual-->
        <!--                      based on their experience, expertise, competencies, actions and/or achievements within a community,-->
        <!--                      industry, or the marketplace at large.-->
        <!--                    </iText>-->
        <!--                  </template>-->
        <!--                </iCollapsablePanel>-->
        <!--              </iRow>-->
        <!--            </template>-->
        <!--          </SplashColumnContainer>-->
        <!--        </iRow>-->

        <SplashJoinWaitingListContainer variant="alternative" @join-waitlist="joinWaitlist" />
      </iColumn>
    </iRow>

    <SplashFooter />
  </SplashWrapper>
</template>

<script>
import SplashColumnContainer from "@/components/splash/SplashColumnContainer";
import SplashTextColumn from "@/components/splash/SplashTextColumn";
import SplashJoinWaitingListContainer from "@/components/splash/SplashJoinWaitingListContainer";
import SplashFooter from "@/components/splash/SplashFooter";
import SplashWrapper from "@/components/splash/SplashWrapper";

export default {
  name: "Splash",
  components: { SplashWrapper, SplashFooter, SplashJoinWaitingListContainer, SplashTextColumn, SplashColumnContainer },
  data() {
    return {
      images: [
        {
          src: "/images/splash/splash-1.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-2.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-3.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-4.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-5.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-6.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-7.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-8.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-9.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-10.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-11.png",
          alt: "placeholder",
        },
        {
          src: "/images/splash/splash-12.png",
          alt: "placeholder",
        },
      ],
      imageWidth: 616,
    };
  },
  mounted() {
  },
  methods: {
    joinWaitlist() {
      // TODO: Implement
    },
  },
  styleGuide: () => ({
    marketingHeadingFontSize: { "size.font": "displayMedium" },
    tabletMarketingHeadingFontSize: { "size.font": "displayMedium" },
    mobileMarketingHeadingFontSize: { "size.font": "displayVerySmall" },
    mobileMarketingHeadingFontWeight: { "font.weight": "dark" },

    marketingTextFontSize: { "size.font": "extraLarge" },
    tabletMarketingTextFontSize: { "size.font": "extraLarge" },
    mobileMarketingTextFontSize: { "size.font": "large" },

  }),
};
</script>

<style scoped lang="scss">
.marketing-heading {
  max-width: 925px;
  padding: 0px 20px;
  @include phone {
  }
  .i-display-text {
    font-size: v-bind("$getStyles.marketingHeadingFontSize");

    @include tablet {
      font-size: v-bind("$getStyles.tabletMarketingHeadingFontSize");
    }

    @include phone {
      font-size: v-bind("$getStyles.mobileMarketingHeadingFontSize");
      font-weight:v-bind("$getStyles.mobileMarketingHeadingFontWeight");
    }
  }
}

.left-right {
  padding: 0 20px;
}

.marketing-text {
  max-width: 900px;
  padding: 0 20px;
  @include phone {
  }
  .i-heading {
    font-size: v-bind("$getStyles.marketingTextFontSize");

    @include tablet {
      font-size: v-bind("$getStyles.tabletMarketingTextFontSize");
    }

    @include phone {
      font-size: v-bind("$getStyles.mobileMarketingTextFontSize");
    }
  }
}
.mirror {
  transform: scalex(-1);
}
</style>
