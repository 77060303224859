<template>
  <iRow class="message-actions" vertical-align="middle" width="hug">
    <iRow
      v-if="conversationSettings.allowsUpvoteAndDownvote"
      gap="extraSmall"
      width="hug"
      wrap="nowrap"
    >
      <iColumn width="hug">
        <iIcon
          :disabled="!isActive"
          :is-loading="loadingUpVotedMessage"
          :variant="userUpVoted ? 'error' : 'primary'"
          icon="arrow-up"
          @click="handleUpVote"
        />
      </iColumn>
      <iText :variant="isActive? 'standard' : 'subtle'">
        {{ getVotes }}
      </iText>
      <iColumn width="hug">
        <iIcon
          :disabled="!isActive"
          :is-loading="loadingDownVotedMessage"
          :variant="userDownVoted? 'error' : 'primary'"
          icon="arrow-down"
          @click="handleDownVote"
        />
      </iColumn>
    </iRow>
    <iRow
      v-if="conversationSettings.allowsLikes"
      :disabled="!isActive"
      gap="extraSmall"
      width="hug"
      @click="handleLike"
    >
      <iIcon
        :is-loading="loadingLikedMessage"
        :variant="userLiked ? 'error' : 'primary'"
        icon="heart"
      />
      <iText :variant="isActive? 'standard' : 'subtle'">
        {{ numLikes }}
      </iText>
    </iRow>
    <iColumn v-if="showReplyButton" width="hug">
      <iRow
        :disabled="!isActive"
        class="reply-btn"
        gap="extraSmall"
        variant="tertiary"
        @click="reply"
      >
        <iIcon icon="comment" />
        <iText :variant="isActive? 'standard' : 'subtle'">
          {{ getReplies }}
        </iText>
      </iRow>
    </iColumn>
    <ConversationMenu
      :is-author="isAuthor"
      :is-loading="isConversationMenuLoading"
      :is-moderator="isModerator"
      :message-id="messageId"
      :moderation-enabled="moderationEnabled"
      :moderation-status="moderationStatus"
      :settings="conversationSettings"
      :status="status"
      @block="blockComment"
      @delete="deleteComment"
      @edit="turnOnIsEditing"
      @report="report"
      @undelete="unDeleteComment"
    />
  </iRow>
  <iRow v-if="showModerationButtons" align="between">
    <iColumn width="hug">
      <iButton
        :is-loading="loadingApprovedMessage"
        variant="secondary"
        @click="approveComment"
      >
        Approve
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton
        :is-loading="loadingBlockedMessage"
        variant="secondary"
        @click="blockComment"
      >
        Block
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton :is-loading="loadingEscalatedMessage" variant="secondary" @click="escalateComment">
        Escalate
      </iButton>
    </iColumn>
  </iRow>
</template>

<script>
import { mapState } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { useGeneralStore } from "@/stores/general-store";
import ConversationMenu from "@/components/conversations/ConversationMenu";
import { OFFENSE_OPTIONS } from "@/constants/conversations-constants";

export default {
  name: "ConversationFooter",
  components: {
    ConversationMenu,
  },
  props: {
    currentMessage: {
      type: String,
      required: true,
    },
    voteScore: {
      type: Number,
      required: false,
      default: "0",
    },
    numReplies: {
      type: Number,
      required: false,
      default: "0",
    },
    numLikes: {
      type: Number,
      required: false,
      default: "0",
    },
    authorId: {
      type: Number,
      required: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    showReplying: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowReplying: {
      type: Boolean,
      required: false,
      default: true,
    },
    userLiked: {
      type: Boolean,
      required: true,
    },
    userUpVoted: {
      type: Boolean,
      required: true,
    },
    userDownVoted: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "upvote",
    "revokeupvote",
    "revokedownvote",
    "downvote",
    "like",
    "unlike",
    "delete",
    "undelete",
    "block",
    "reply",
    "approve",
    "report",
    "startEditing",
    "escalate",
  ],
  data() {
    return {
      OFFENSE_OPTIONS,
      isSharing: false,
      editMessage: "",
    };
  },
  computed: {
    ...mapState(useConversationStore, [
      "isLoading",
      "isModerator",
      "moderationEnabled",
      "conversationSettings",
      "isMessageLoading",
    ]),
    ...mapState(useGeneralStore, ["userData"]),
    isConversationMenuLoading() {
      if (!this.isMessageLoading[this.messageId]) {
        return false;
      }
      if (this.isMessageLoading[this.messageId].delete) {
        return true;
      }
      if (this.isMessageLoading[this.messageId].undelete) {
        return true;
      }
      return false;
    },
    showBlockButton() {
      return !this.isAuthor && this.isModerator && this.moderationStatus === "approved" && this.moderationEnabled;
    },
    showModerationButtons() {
      return this.moderationStatus !== "approved" && this.isModerator && this.moderationEnabled;
    },
    isAuthor() {
      return this.userData.user_id === this.authorId;
    },
    showReplyButton() {
      return this.conversationSettings.allowsReply && this.showReplying && this.allowReplying;
    },
    getReplies() {
      if (this.numReplies === 0) {
        return "Reply";
      }
      return this.numReplies;
    },
    getVotes() {
      if (this.voteScore === 0) {
        return "Vote";
      }
      return this.voteScore;
    },
    isActive() {
      return this.status === "active";
    },
    loadingUpVotedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["upvote"] || this.isMessageLoading[this.messageId]["revoke-upvote"]);
    },
    loadingDownVotedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["downvote"] || this.isMessageLoading[this.messageId]["revoke-downvote"]);
    },
    loadingLikedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["like"] || this.isMessageLoading[this.messageId]["revoke-like"]);
    },
    loadingApprovedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["approve"];
    },
    loadingEscalatedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["escalate"];
    },
    loadingBlockedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["block"];
    },
  },
  created() {
    this.editMessage = this.message;
  },
  methods: {
    handleUpVote() {
      if (this.userUpVoted) {
        return this.revokeUpVote();
      }
      this.upVote();
    },
    handleDownVote() {
      if (this.userDownVoted) {
        return this.revokeDownVote();
      }
      this.downVote();
    },
    handleLike() {
      if (this.userLiked) {
        return this.unlike();
      }
      this.like();
    },
    turnOnIsEditing() {
      this.showMenuOptions = false;
      if (!this.isAuthor) {
        return;
      }
      this.$emit("startEditing");
    },
    upVote() {
      this.$emit("upvote");
    },
    revokeUpVote() {
      this.$emit("revokeupvote");
    },
    downVote() {
      this.$emit("downvote");
    },
    revokeDownVote() {
      this.$emit("revokedownvote");
    },
    like() {
      this.$emit("like");
    },
    unlike() {
      this.$emit("unlike");
    },
    deleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.$emit("delete");
    },
    unDeleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.$emit("undelete");
    },
    report(reason) {
      this.$emit("report", reason);
    },
    reply() {
      this.showMenuOptions = false;
      this.$emit("reply");
    },
    blockComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("block", { reason: this.OFFENSE_OPTIONS[0].value, id: this.messageId });
    },
    approveComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("approve");
    },
    escalateComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("escalate");
    },
  },
};
</script>
<style lang="scss" scoped>
.reply-btn {
  cursor: pointer;
}
</style>
