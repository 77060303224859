<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_CREATE_PUBLICATION"
    title="Create Publication"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Create Publication"
    align="left"
    width="full"
  >
    <iColumn>
      <!-- removing temporarily -->
      <!-- <iTextInput v-model="blUid" label="BL UID" /> -->

      <iRow wrap="wrap" vertical-align="middle" align="between">
        <iColumn width="hug">
          <iTextInput v-model="name" placeholder="Publication Name" :is-loading="isLoading" />
        </iColumn>
        <iColumn width="hug">
          <iButton :disabled="!isValid" :is-loading="isLoading" @click="submitPublication">
            Add publication
          </iButton>
        </iColumn>
      </iRow>
      <iRow>
        <iTextInput v-model="domain" placeholder="Domain" />
      </iRow>
      <iRow>
        <iSelect
          v-model="theme"
          return-value
          value-field="value"
          label="Publication Theme"
          :width="500"
          :items="mappedThemes"
        />
      </iRow>
      <iRow>
        <iSubHeading>
          Select a starting template
        </iSubHeading>
      </iRow>
      <iRow
        vertical-align="top"
        wrap="wrap"
        height="hug"
        width="hug"
      >
        <iColumn
          v-for="(template, index) in PUBLICATION_TEMPLATES"
          :key="index"
          :class="{'template-card':true, 'selected':isSelected(template)}"
          :width="250"
          height="hug"
        >
          <iOverlay :show-overlay-trigger="template.availability ==='available' ? 'hover':'always'">
            <iRow height="fill">
              <iImageContainer
                src="https://placehold.co/300x300"
                :height="200"
                :width="300"
                object-fit="cover"
              />
            </iRow>
            <iRow class="template-card-description" height="fill">
              <iColumn width="fill">
                <iRow>
                  <iTitle>
                    {{ template.name }}
                  </iTitle>
                </iRow>
                <iRow width="fill">
                  <iText>
                    {{ template.description }}
                  </iText>
                </iRow>
              </iColumn>
            </iRow>
            <template #overlay>
              <iColumn
                vertical-align="middle"
                align="center"
                width="fill"
                height="fill"
                class="template-card-overlay"
              >
                <iRow v-if="template.availability === 'available'" height="hug" width="hug">
                  <iButton v-if="!isSelected(template)" variant="secondary" @click="selectTemplate(index)">
                    Select
                  </iButton>
                  <iButton v-else variant="secondary" :disabled="true">
                    Selected
                  </iButton>
                </iRow>
                <iRow v-else-if="template.availability === 'pending'" height="hug" width="hug">
                  <iTitle variant="subtle">
                    Coming Soon...
                  </iTitle>
                </iRow>
                <iRow v-else height="hug" width="hug">
                  <iTitle variant="subtle">
                    Unavailable
                  </iTitle>
                </iRow>
                <iRow v-if="template.availability === 'available'" height="hug" width="hug">
                  <iButton class="contrast-text" variant="tertiary" @click="viewDetails(template)">
                    Details
                  </iButton>
                </iRow>
              </iColumn>
            </template>
          </iOverlay>
        </iColumn>
      </iRow>
      <iRow>
        <TemplateDetails
          v-if="viewConfig.name"
          :visible="isDetailsOpen"
          :configuration="viewConfig.configuration"
          :title="viewConfig.name"
          @close-details="closeConfigDetails"
        />
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import TemplateDetails from "@/components/TemplateDetails";
import { PAGE_CREATE_PUBLICATION, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { PUBLICATION_TEMPLATES } from "@/constants/template-config-constants";
import { useThemesStore } from "@/stores/themes-store";

export default {
  name: "CreatePublication",
  components: {
    PageWrapper,
    TemplateDetails,
  },
  data() {
    return {
      PAGE_CREATE_PUBLICATION,
      SECTION_PUBLICATIONS,
      name: "",
      domain: "",
      theme: "0",
      blUid: 0,
      PUBLICATION_TEMPLATES,
      selectedConfig: PUBLICATION_TEMPLATES.Blog,
      viewConfig: {},
      isDetailsOpen: false,
    };
  },
  styleGuide: () => ({
    templateCardPadding: { "size.spacing": "none" },
    templateCardDescriptionPadding: { "size.spacing": "standard" },
    templateCardBorderColor: { "color.border": "dark" },
    templateCardBorderWidth: { "size.border": "thin" },
    templateCardSelectedBackground: { "color.background": "striking" },
    templateCardBorderRadius: { "size.borderRadius": "large" },
  }),
  computed: {
    ...mapState(usePublicationStore, ["isLoading", "orgId"] ),
    ...mapState(useThemesStore, ["mappedThemes"]),
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
    isValid() {
      return this.selectedConfig.name && this.selectedConfig.name !== "" && this.name !== "";
    },
  },
  methods: {
    ...mapActions(usePublicationStore, { createPublication: "createPublication" }),
    submitPublication() {
      this.createPublication( this.name, this.domain, parseInt(this.blUid), this.selectedConfig, parseInt(this.theme) );
    },
    viewDetails(template) {
      this.viewConfig = template;
      this.openConfigDetails();
    },
    openConfigDetails() {
      this.isDetailsOpen = true;
    },
    closeConfigDetails() {
      this.isDetailsOpen = false;
    },
    isSelected(template) {
      return this.selectedConfig.name == template.name;
    },
    selectTemplate(index) {
      this.selectedConfig = PUBLICATION_TEMPLATES[index];
    },
  },
};
</script>
<style scoped lang="scss">
.template-card{
  padding:v-bind("$getStyles.templateCardPadding");
  border: v-bind("$getStyles.templateCardBorderWidth") solid v-bind("$getStyles.templateCardBorderColor");
  border-radius: v-bind("$getStyles.templateCardBorderRadius");
  overflow: hidden;
}
.template-card-description{
  padding:v-bind("$getStyles.templateCardDescriptionPadding");
}
.selected{
  background-color:v-bind("$getStyles.templateCardSelectedBackground");
}
.template-card-overlay{
  background-color: rgba(0, 0, 0, 50%);
  height: 100%;
}
.contrast-text:deep(.i-text){
    color:white;
}
</style>
