// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container[data-v-7471467b] {
  flex-grow: 0;
  flex-basis: fit-content;
}
@media (max-width: 768px) {
.size-input[data-v-7471467b] {
    width: 100%;
    max-width: 100%;
}
.size-input[data-v-7471467b] .i-text-input {
    width: 100%;
    max-width: 100%;
}
.input-container[data-v-7471467b] {
    flex-grow: 1;
    flex-basis: auto;
    width: 100%;
}
}
.input-disabled[data-v-7471467b] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
[data-v-7471467b] .i-select-container > .label {
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/ThemeConfiguration.vue","webpack://./ThemeConfiguration.vue","webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,uBAAA;ACDF;ACFE;AFOA;IACE,WAAA;IACA,eAAA;ACDF;ADEE;IACE,WAAA;IACA,eAAA;ACAJ;ADGA;IACE,YAAA;IACA,gBAAA;IACA,WAAA;ACDF;AACF;ADGA;EACE,mBAAA;EACA,oBAAA;EACA,YAAA;ACDF;ADIA;EACE,oBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.input-container {\n  flex-grow: 0;\n  flex-basis: fit-content;\n}\n\n@include phone {\n  .size-input {\n    width: 100%;\n    max-width: 100%;\n    & :deep(.i-text-input) {\n      width: 100%;\n      max-width: 100%;\n    }\n  }\n  .input-container {\n    flex-grow: 1;\n    flex-basis: auto;\n    width: 100%;\n  }\n}\n.input-disabled {\n  cursor: not-allowed;\n  pointer-events: none;\n  opacity: 0.5;\n}\n\n:deep(.i-select-container > .label) {\n  padding-bottom: 10px;\n}\n",".input-container {\n  flex-grow: 0;\n  flex-basis: fit-content;\n}\n\n@media (max-width: 768px) {\n  .size-input {\n    width: 100%;\n    max-width: 100%;\n  }\n  .size-input :deep(.i-text-input) {\n    width: 100%;\n    max-width: 100%;\n  }\n  .input-container {\n    flex-grow: 1;\n    flex-basis: auto;\n    width: 100%;\n  }\n}\n.input-disabled {\n  cursor: not-allowed;\n  pointer-events: none;\n  opacity: 0.5;\n}\n\n:deep(.i-select-container > .label) {\n  padding-bottom: 10px;\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
