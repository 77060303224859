<template>
  <iStyledCard
    :splash-image="publicationLogo"
    :title="publication.name"
    :badge="snakeToTitle(publication.status)"
    :subtitle="publication.domain"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/publication/${publication.publication_id}`"
  >
    <template #footer>
      <iRow wrap="nowrap" vertical-align="middle">
        <iLink width="fill" :href="`/publication/${publication.publication_id}`">
          <iButton width="fill" variant="secondary">
            View Configuration
          </iButton>
        </iLink>
        <iDropdownMenu
          :items="viewMenuItems"
          :show-dropdown="showViewDropdown"
          position="right"
          @close="showViewDropdown = false"
        >
          <template #trigger>
            <iIcon icon="visibility" @click="showViewDropdown = true" />
          </template>
        </iDropdownMenu>
      </iRow>
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_PUBLICATION_IMAGE } from "@/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";
export default {
  name: "PublicationCard",
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewDropdown: false,
      DEFAULT_PUBLICATION_IMAGE,
    };
  },
  computed: {
    viewMenuItems() {
      return [
        {
          name: "View Live",
          actionValue: `/my-publication/${this.publication.publication_id}`,
          label: "View Live",
        },
        {
          name: "View Staging",
          actionValue: `/my-publication/staging/${this.publication.publication_id}`,
          label: "View Staging",
        },
      ];
    },
    publicationLogo() {
      if (!this.publication.publication_logo) {
        return this.DEFAULT_PUBLICATION_IMAGE;
      }
      return this.publication.publication_logo;
    },
  },
  methods: {
    snakeToTitle,
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
  }),
};

</script>
<style scoped lang="scss">
.publication-card{
  border:v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details{
  padding-top:v-bind("$getStyles.paddingTop");
  padding-left:v-bind("$getStyles.paddingLeft");
  padding-right:v-bind("$getStyles.paddingRight");
  padding-bottom:v-bind("$getStyles.paddingBottom");
}
</style>
