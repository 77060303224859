<template>
  <PageWrapper :active-page="PAGE_JAVASCRIPT_TAG" :active-section="SECTION_MONETIZATION">
    <div class="container">
      <div style="font-size: 30px; margin-top: 10px; margin-bottom: 10px;">
        Install Affiliate Link JavaScript
      </div>
      <div style="margin-bottom: 20px;">
        Simply insert this HTML snippet into the <code>head</code> tag of your website, to unlock the power to transform
        your
        affiliate marketing strategy, maximizing your earnings effortlessly while enhancing user engagement.
      </div>
      <div style="margin-bottom: 20px;">
        Dynamic links adapt seamlessly across your website, ensuring relevant offers greet your audience wherever they
        navigate, fostering a superior browsing experience.
      </div>
      <div v-if="getInstallScript" style="display: flex; margin-bottom: 20px; gap: 10px;">
        <!-- eslint-disable vue/no-v-html -->
        <code
          style="padding-left: 10px; min-height: 35px; border-radius: 5px; border: black 1px solid; flex-grow: 1; display: flex;
          flex-direction: column; justify-content: center;"
          v-html="getInstallScript"
        />
        <!-- eslint-enable vue/no-v-html -->
        <button @click="copyCode">
          Copy
        </button>
      </div>
      <div v-else>
        <div>
          Start monetizing your website effortlessly by installing affiliate links through Java Scripts –
          simply add your domain to your account to get started.
        </div>
        <div style="margin-bottom: 20px; margin-top: 10px; display: flex; width: 75%">
          <input
            v-model="publisherDomain"
            class="tracking-link-input"
            placeholder="Paste a URL"
            style="flex-grow: 1"
          >
          <button :disabled="!publisherDomain" class="tracking-link-generate-button" @click="registerPublisher">
            Register Domain
          </button>
        </div>
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { usePublisherStore } from "@/stores/publisher-store";
import { mapActions, mapState } from "pinia";
import { PAGE_JAVASCRIPT_TAG, SECTION_MONETIZATION } from "@/constants/nav-constants";
import { useGeneralStore } from "@/stores/general-store";

export default {
  name: "JavascriptTag",
  components: {
    PageWrapper,
  },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      token: "",
      id: 1,
      publisherDomain: "",
      PAGE_JAVASCRIPT_TAG,
      SECTION_MONETIZATION,
      getInstallScript: "abc",
    };
  },
  computed: {
    ...mapState(usePublisherStore, [
      "getInstallScript",
    ]),
    ...mapState(useGeneralStore, [
      "userId",
    ]),
  },
  method: {
    ...mapActions(usePublisherStore, [
      "addPublisherDomain",
    ]),
    registerPublisher() {
      this.addPublisherDomain(this.publisherDomain, this.userId, this.ignoreQueryParams);
    },
    copyCode() {
      let code = this.getInstallScript.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
      navigator.clipboard.writeText(code);
    },
  },
};
</script>

<style scoped>
.container {
  margin: 20px auto 0 auto;
  padding: 0 10px;
  max-width: 1280px;
  background: #fff;
  border: 1px solid lightgrey;
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgba(193, 192, 192, 0.5);
}

.tracking-link-input {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  height: 35px;
}

</style>
