<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_POSTS"
    title="Your Posts"
    :breadcrumbs="breadcrumbs"
    page-name="Posts"
    align="left"
    width="full"
  >
    <iRow gap="large" vertical-align="middle">
      <iColumn width="hug">
        <iButton @click="navigateToCreatePost">
          Create Post
        </iButton>
      </iColumn>
    </iRow>

    <iLoadingSpinner v-if="isLoading" />
    <iRow v-else class="posts">
      <PostPreview v-for="(post, index) in posts" :key="index" :post="post" />
    </iRow>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import PostPreview from "@/components/PostPreview";

import {
  PAGE_PUBLICATION_POSTS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "ViewPublicationPostList",
  components: { PostPreview, PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publication", "posts", "isLoading", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  async created() {
  },
  methods: {

    navigateToCreatePost() {
      window.location.href = `/publication/${this.publicationId}/posts/create`;
    },
  },
};
</script>

<style scoped  lang="scss">
.heading {
  margin-bottom: 0;
}

.posts{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:2rem;
}
</style>
