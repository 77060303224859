<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURE_DIGITAL_PRODUCTS"
    title="Your Digital Products"
    :breadcrumbs="breadcrumbs"
    page-name="Digital Products"
    align="left"
    width="full"
  >
    <iLink variant="button" :href="`/publication/${publicationId}/digital-products/create`">
      New Digital Product
    </iLink>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_FEATURE_DIGITAL_PRODUCTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationDigitalProducts",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_FEATURE_DIGITAL_PRODUCTS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/features`, label: "Features" },
      ];
    },
  },
};
</script>

<style scoped>
</style>
