<template>
  <iStyledCard
    :splash-image="publicationSplashImage"
    :title="publication.name"
    :badge="mappedPublicationStatus"
    :subtitle="subTitle"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/community/publications/${publication.publication_id}/profile`"
  >
    <template #title>
      <iRow vertical-align="middle" wrap="nowrap">
        <iImageContainer
          object-fit="cover"
          :height="35"
          :width="35"
          shape="circle"
          :src="publicationLogo"
        />
        <iSubHeading font-weight="dark">
          {{ publication.name }}
        </iSubHeading>
      </iRow>
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";
export default {
  name: "PublicationCard",
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewDropdown: false,
    };
  },
  computed: {
    publicationSplashImage() {
      if (!this.publication.splash_image) {
        return DEFAULT_SPLASH_IMAGE;
      }
      return this.publication.splash_image;
    },
    subTitle() {
      return this.publication.domain ?? `${window.location.origin}/my-publication/${this.publication.publication_id}`;
    },
    publicationLogo() {
      if (!this.publication.publication_logo) {
        return DEFAULT_ORG_LOGO;
      }
      return this.publication.publication_logo;
    },
    mappedPublicationStatus() {
      switch (this.publication.status) {
        case "active":
          return "Live";
        case "draft":
          return "Draft";
        default:
          return "Offline";
      }
    },
  },
  methods: {
    snakeToTitle,
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
  }),
};

</script>
<style scoped lang="scss">
.publication-card {
  border: v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details {
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
}
</style>
