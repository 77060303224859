// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-card[data-v-0af0ffda] {
  border: var(--0af0ffda-\\\$getStyles\\.borderSize) solid var(--0af0ffda-\\\$getStyles\\.borderColor);
  border-radius: var(--0af0ffda-\\\$getStyles\\.borderRadius);
}
.org-details[data-v-0af0ffda] {
  padding-top: var(--0af0ffda-\\\$getStyles\\.paddingTop);
  padding-left: var(--0af0ffda-\\\$getStyles\\.paddingLeft);
  padding-right: var(--0af0ffda-\\\$getStyles\\.paddingRight);
  padding-bottom: var(--0af0ffda-\\\$getStyles\\.paddingBottom);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/smart-feed/org/layouts/OrgCardSmall.vue","webpack://./OrgCardSmall.vue"],"names":[],"mappings":"AAEA;EACE,8FAAA;EACA,wDAAA;ACDF;ADIA;EACE,oDAAA;EACA,sDAAA;EACA,wDAAA;EACA,0DAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.org-card {\n  border: v-bind(\"$getStyles.borderSize\") solid v-bind(\"$getStyles.borderColor\");\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.org-details {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n}\n",".org-card {\n  border: v-bind(\"$getStyles.borderSize\") solid v-bind(\"$getStyles.borderColor\");\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.org-details {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
