import { links } from "@/enums/link-enums";
import { useGeneralStore } from "@/stores/general-store";
import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useAffiliateLinkFeedStore = defineSmartFeedPiniaStore("affiliate-link-feed-store", {
  state: () => ({
    entityType: CreatorSmartFeedEngine.AFFILIATE_LINK,
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publisherOrgId: useGeneralStore().orgId,
    },
    tableFields: [
      {
        key: "affiliateLink",
        label: "Affiliate Link",
        width: "350px",
      },
      {
        key: "originalLink",
        label: "Original Link",
        width: "350px",
      },
      {
        key: "advertiserName",
        label: "Advertiser",
        width: "200px",
      },
      {
        key: "myDescription",
        label: "My Description",
      },
      {
        key: "brandName",
        label: "Brand Name",
      },
      {
        key: "productName",
        label: "Product Name",
      },
      {
        key: "clicks",
        label: "Clicks",
      },
      {
        key: "sales",
        label: "Sales",
      },
      {
        key: "totalEarnings",
        label: "Total Earnings",
      },
      {
        key: "pendingEarnings",
        label: "Pending Earnings",
      },
      {
        key: "lockedEarnings",
        label: "Locked Earnings",
      },
      {
        key: "actions",
        label: "",
      },
      {
        key: "actions",
        label: "",
      },
    ],
    isLoading: false,
  }),
  getters: {
    affiliateApiV1() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `${this.affiliateApiV1}/publisher-affiliate-link/publisher-links/org/${this.metadata.publisherOrgId}/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
      );
    },
  },
});
