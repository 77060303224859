// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-container:has(.i-dropdown .i-dropdown-content) input {
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/conversations/ReplyWidget.vue","webpack://./ReplyWidget.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.message-container:has(.i-dropdown .i-dropdown-content) input {\n  z-index: -1;\n}\n",".message-container:has(.i-dropdown .i-dropdown-content) input {\n  z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
