import { BaseSmartFeedEngine } from "@bloglovin/vue-framework";
import AffiliateLinkCardLarge from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkCardLarge";
import AffiliateLinkCardSmall from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkCardSmall";
import AffiliateLinkListItem from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkListItem";
import AffiliateLinkTableRow from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkTableRow";
import OrgCardSmall from "@/components/smart-feed/org/layouts/OrgCardSmall";
import { useAffiliateLinkEntityStore } from "@/stores/smart-feed/entity/affiliate-link-entity-store";
import { usePublicationEntityStore } from "@/stores/smart-feed/entity/publication-entity-store";
import PublicationCardSmall from "@/components/smart-feed/publication/layouts/PublicationCardSmall";
import { useOrgEntityStore } from "@/stores/smart-feed/entity/org-entity-store";

const CreatorSmartFeedEngine = class extends BaseSmartFeedEngine {
  static AFFILIATE_LINK = "affiliateLink";
  static PUBLICATION = "publication";
  static ORG = "org";

  config = {
    [CreatorSmartFeedEngine.AFFILIATE_LINK]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useAffiliateLinkEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: AffiliateLinkCardSmall,
        [CreatorSmartFeedEngine.LAYOUT_CARD_LARGE]: AffiliateLinkCardLarge,
        [CreatorSmartFeedEngine.LAYOUT_LIST]: AffiliateLinkListItem,
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: AffiliateLinkTableRow,
      },
    },
    [CreatorSmartFeedEngine.PUBLICATION]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: usePublicationEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: PublicationCardSmall,
      },
    },
    [CreatorSmartFeedEngine.ORG]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useOrgEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: OrgCardSmall,
      },
    },
  };
};

export default CreatorSmartFeedEngine;
