import { links } from "@/enums/link-enums";
import { useGeneralStore } from "@/stores/general-store";
import { ToastBuilder } from "@bloglovin/vue-component-library";
import { defineStore } from "pinia";

export const usePaymentStore = defineStore("payment-store", {
  state: () => ({
    digitalWallet: {},
    loading: {
      withdrawal: false,
      createOneTimeProductAndPrice: false,
      createSubscriptionProductAndPrice: false,
      createOneTimeCheckoutSession: false,
      createSubscriptionCheckoutSession: false,
      updateProduct: false,
      updatePrice: false,
    },
    errors: {
      withdrawal: "",
      createOneTimeProductAndPrice: "",
      createSubscriptionProductAndPrice: "",
      createOneTimeCheckoutSession: "",
      createSubscriptionCheckoutSession: "",
      updateProduct: "",
      updatePrice: "",
    },
  }),

  getters: {
    showDigitalWalletBalance: state => state.digitalWallet.tipaltiStatus !== "not_setup",
    digitalWalletTipaltiStatus: state => state.digitalWallet.tipaltiStatus,
    digitalWalletBalanceValue: state => Number(state.digitalWallet.balance),
    digitalWalletCurrency: state => state.digitalWallet.currency,
    orgId: () => useGeneralStore().orgId,
    digitalWalletBalanceString: state => new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: state.digitalWallet.currency,
    }).format(Number(state.digitalWallet.balance)),
    canWithdrawFromDigitalWallet: state => Number(state.digitalWallet.balance) > 100 &&
      state.digitalWallet.tipaltiStatus === "payable",
    paymentsV1ApiUrl: () => useGeneralStore().getLink(links.paymentsApiV1),
  },

  actions: {
    setDigitalWalletData(data) {
      this.digitalWallet = data;
    },

    async createWithdrawal() {
      this.loading.withdrawal = true;
      this.errors.withdrawal = "";

      return this.apiPost(
        `/digital-wallet/${this.orgId}/create-withdrawal`,
        { amount: this.digitalWalletBalanceValue },
        data => {
          this.loading.withdrawal = false;
          this.showSuccessToast(
            "Withdrawal In Progress",
            "Withdrawal request has been created successfully.",
          );
          return data;
        },
        error => {
          this.loading.withdrawal = false;
          this.errors.withdrawal =
            error.response?.data?.message || "Unable to create withdrawal";
          this.showErrorToast("Withdrawal Error", this.errors.withdrawal);
          throw error;
        },
      );
    },

    async createOneTimeProductAndPrice(
      name,
      description,
      unitAmount,
      currency,
      digitalProductId,
      creatorOrgId,
      publicationId,
      purchasingOrgId,
      active = true,
    ) {
      this.loading.createOneTimeProductAndPrice = true;
      this.errors.createOneTimeProductAndPrice = "";

      return this.apiPost(
        "/stripe/product-price/one-time",
        {
          name,
          description,
          unit_amount: unitAmount,
          currency,
          digital_product_id: digitalProductId,
          creator_org_id: creatorOrgId,
          publication_id: publicationId,
          purchasing_org_id: purchasingOrgId,
          active,
        },
        data => {
          this.loading.createOneTimeProductAndPrice = false;
          return data;
        },
        error => {
          this.loading.createOneTimeProductAndPrice = false;
          this.errors.createOneTimeProductAndPrice =
            error.response?.data?.message ||
            "Unable to create one-time product and price";
          throw error;
        },
      );
    },

    async createSubscriptionProductAndPrice(
      name,
      description,
      unitAmount,
      currency,
      subscriptionPeriod,
      subscriptionPlanId,
      userId,
      fanOrgId,
      publicationId,
      creatorOrgId,
      active = true,
    ) {
      this.loading.createSubscriptionProductAndPrice = true;
      this.errors.createSubscriptionProductAndPrice = "";

      return this.apiPost(
        "/stripe/product-price/subscription",
        {
          name,
          description,
          unit_amount: unitAmount,
          currency,
          subscription_period: subscriptionPeriod,
          subscription_plan_id: subscriptionPlanId,
          user_id: userId,
          fan_org_id: fanOrgId,
          publication_id: publicationId,
          creator_org_id: creatorOrgId,
          active,
        },
        data => {
          this.loading.createSubscriptionProductAndPrice = false;
          return data;
        },
        error => {
          this.loading.createSubscriptionProductAndPrice = false;
          this.errors.createSubscriptionProductAndPrice =
            error.response?.data?.message ||
            "Unable to create subscription product and price";
          throw error;
        },
      );
    },

    async createOneTimeCheckoutSession(
      priceId,
      quantity,
      successUrl,
      cancelUrl,
      digitalProductId,
      creatorOrgId,
      publicationId,
      purchasingOrgId,
      orgEmail,
    ) {
      this.loading.createOneTimeCheckoutSession = true;
      this.errors.createOneTimeCheckoutSession = "";

      return this.apiPost(
        "/stripe/checkout/session/one-time",
        {
          price_id: priceId,
          quantity,
          success_url: successUrl,
          cancel_url: cancelUrl,
          digital_product_id: digitalProductId,
          creator_org_id: creatorOrgId,
          publication_id: publicationId,
          purchasing_org_id: purchasingOrgId,
          org_email: orgEmail,
        },
        data => {
          this.loading.createOneTimeCheckoutSession = false;
          return data;
        },
        error => {
          this.loading.createOneTimeCheckoutSession = false;
          this.errors.createOneTimeCheckoutSession =
            error.response?.data?.message ||
            "Unable to create one-time checkout session";
          throw error;
        },
      );
    },

    async createSubscriptionCheckoutSession(
      priceId,
      successUrl,
      cancelUrl,
      subscriberId,
      userId,
      fanOrgId,
      publicationId,
      subscriptionPlanId,
      creatorOrgId,
      orgEmail,
    ) {
      this.loading.createSubscriptionCheckoutSession = true;
      this.errors.createSubscriptionCheckoutSession = "";

      return this.apiPost(
        "/stripe/checkout/session/subscription",
        {
          price_id: priceId,
          success_url: successUrl,
          cancel_url: cancelUrl,
          subscriber_id: subscriberId,
          user_id: userId,
          fan_org_id: fanOrgId,
          publication_id: publicationId,
          subscription_plan_id: subscriptionPlanId,
          creator_org_id: creatorOrgId,
          org_email: orgEmail,
        },
        data => {
          this.loading.createSubscriptionCheckoutSession = false;
          return data;
        },
        error => {
          this.loading.createSubscriptionCheckoutSession = false;
          this.errors.createSubscriptionCheckoutSession =
            error.response?.data?.message ||
            "Unable to create subscription checkout session";
          throw error;
        },
      );
    },

    async updateProduct(
      productId,
      name = null,
      description = null,
      active = null,
    ) {
      this.loading.updateProduct = true;
      this.errors.updateProduct = "";

      return this.apiPost(
        "/stripe/product/update",
        {
          product_id: productId,
          name,
          description,
          active,
        },
        data => {
          this.loading.updateProduct = false;
          return data;
        },
        error => {
          this.loading.updateProduct = false;
          this.errors.updateProduct =
            error.response?.data?.message || "Unable to update product";
          throw error;
        },
      );
    },

    async updatePrice(priceId, active = null, nickname = null) {
      this.loading.updatePrice = true;
      this.errors.updatePrice = "";

      return this.apiPost(
        "/stripe/price/update",
        {
          price_id: priceId,
          active,
          nickname,
        },
        data => {
          this.loading.updatePrice = false;
          return data;
        },
        error => {
          this.loading.updatePrice = false;
          this.errors.updatePrice =
            error.response?.data?.message || "Unable to update price";
          throw error;
        },
      );
    },

    showSuccessToast(title, text) {
      new ToastBuilder()
          .setTitle(title)
          .setText(text)
          .setToastType(ToastBuilder.TOAST_TYPE_SUCCESS)
          .setTimeout(5000)
          .setClosable(true)
          .build();
    },

    showErrorToast(title, text) {
      new ToastBuilder()
          .setTitle(title)
          .setText(text)
          .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
          .setTimeout(5000)
          .setClosable(true)
          .build();
    },
  },
});
