<template>
  <iColumn>
    <iRow vertical-align="middle" class="publication-header-wrapper">
      <iImageContainer
        :src="splashImage"
        :height="350"
        object-fit="cover"
        width="fill"
        class="splash-image"
      />
      <iRow vertical-align="middle" class="publication-header">
        <iImageContainer
          class="publication-header-logo"
          shape="circle"
          :width="120"
          :height="120"
          :src="logoImage"
        />
        <iColumn gap="none" width="hug">
          <iSpace :height="10" />
          <iSubHeading>{{ title }}</iSubHeading>
          <slot name="below-title" />
        </iColumn>
      </iRow>
    </iRow>
    <iRow>
      <slot />
    </iRow>
  </iColumn>
</template>

<script>
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";

export default {
  name: "PublicationProfileWrapper",
  props: {
    splashImage: {
      type: String,
      required: false,
      default: DEFAULT_SPLASH_IMAGE,
    },
    logoImage: {
      type: String,
      required: false,
      default: DEFAULT_ORG_LOGO,
    },
    title: {
      type: String,
      required: true,
    },
  },
  styleGuide: () => ({
    logoBorderWidth: { "size.border": "thick" },
    logoBorderColor: { "color.border": "dark" },
    logoBackground: { "color.background": "light" },
  }),
};
</script>

<style scoped lang="scss">
.publication-header-wrapper{
  position: relative;
}

.publication-header {
  position: relative;
  bottom: 30px;
}

.publication-header-logo {
  border: v-bind("$getStyles.logoBorderWidth") solid v-bind("$getStyles.logoBorderColor");
  background: v-bind("$getStyles.logoBackground")
}
</style>
