// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i-footer[data-v-24725528] {
  width: 100%;
}
.i-footer .footer[data-v-24725528] {
  padding: 30px 60px;
}
@media (max-width: 1365px) {
.i-footer .footer[data-v-24725528] {
    flex-direction: column;
}
}
@media (max-width: 768px) {
.i-footer .footer[data-v-24725528] {
    padding: 30px 30px;
}
}
@media (max-width: 1365px) {
.cc-container[data-v-24725528] {
    width: 100%;
}
}
@media (max-width: 1365px) {
.link-container[data-v-24725528] {
    align-items: flex-start;
}
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/splash/SplashFooter.vue","webpack://./SplashFooter.vue","webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF;ADGE;EACE,kBAAA;ACDJ;ACEE;AFFA;IAII,sBAAA;ACAJ;AACF;ACTE;AFIA;IAQI,kBAAA;ACCJ;AACF;ACRE;AFUF;IAEI,WAAA;ACCF;AACF;ACdE;AFeF;IAEI,uBAAA;ACEF;AACF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.i-footer {\n  width: 100%;\n\n  .footer {\n    padding: 30px 60px;\n\n    @include tablet {\n      flex-direction: column;\n    }\n\n    @include phone {\n      padding: 30px 30px;\n    }\n  }\n}\n.cc-container {\n  @include tablet {\n    width: 100%;\n  }\n}\n.link-container {\n  @include tablet {\n    align-items: flex-start;\n  }\n}\n\n",".i-footer {\n  width: 100%;\n}\n.i-footer .footer {\n  padding: 30px 60px;\n}\n@media (max-width: 1365px) {\n  .i-footer .footer {\n    flex-direction: column;\n  }\n}\n@media (max-width: 768px) {\n  .i-footer .footer {\n    padding: 30px 30px;\n  }\n}\n\n@media (max-width: 1365px) {\n  .cc-container {\n    width: 100%;\n  }\n}\n\n@media (max-width: 1365px) {\n  .link-container {\n    align-items: flex-start;\n  }\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
