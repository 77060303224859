<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :breadcrumbs="breadcrumbs"
    page-name="Profile"
    sub-title=""
    align="left"
    width="fixed"
  >
    <iColumn gap="large">
      <iRow vertical-align="middle">
        <AvatarImage :src="logoUrl" size="120px" />
        <iHeading>{{ orgProfileData.name }}</iHeading>
      </iRow>
      <iColumn v-if="hasAdvertiserCapability || hasPublicationCapability" gap="extraSmall">
        <iSubHeading variant="standard" font-size="extraLarge">
          Connect with {{ hasPublicationCapability ? 'Creator' : 'Advertiser' }}
        </iSubHeading>

        <iText v-if="hasExistingConversation">
          Continue your conversation with <iText variant="branded">
            {{ orgProfileData.name }}
          </iText>.
        </iText>
        <iText v-else>
          Start a conversation with  <iText variant="branded">
            {{ orgProfileData.name }}
          </iText>.
        </iText>

        <iButton>Message</iButton>
      </iColumn>
      <iColumn v-if="hasPublicationCapability" gap="extraSmall">
        <iText>
          Invite  <iText variant="branded">
            {{ orgProfileData.name }}
          </iText> to collaborate with you and build a publication together.
        </iText>

        <iButton>Invite</iButton>
      </iColumn>
      <iColumn v-if="hasAdvertiserCapability && orgProfileData.domains.length" gap="extraSmall">
        <iSubHeading variant="standard" font-size="extraLarge">
          Advertiser Websites
        </iSubHeading>
        <iText>
          Visit these websites to find products you may want to recommend to your followers.
        </iText>
        <iColumn gap="extraSmall">
          <iLink
            v-for="(domain, index) in orgProfileData.domains"
            :key="index"
            target="_blank"
            :href="formatDomain(domain.domain)"
          >
            {{ domain.domain }}
          </iLink>
        </iColumn>
      </iColumn>
      <iColumn gap="extraSmall">
        <iSubHeading>
          Description
        </iSubHeading>
        <iText variant="standard">
          <template v-if="orgProfileData.org_description">
            {{ orgProfileData.org_description }}
          </template>
          <template v-else>
            This profile has not added a description
          </template>
        </iText>
        <iColumn>
          <iRow v-if="hasPublicationCapability" vertical-align="middle">
            <iImageContainer src="/images/influencer-icon.png" :height="50" />
            <iText>Creator</iText>
          </iRow>
          <iRow v-if="hasAdvertiserCapability" vertical-align="middle">
            <iImageContainer src="/images/advertiser-icon.png" :height="50" />
            <iText>Advertiser</iText>
          </iRow>
        </iColumn>
      </iColumn>
      <OrgTeam v-if="activeUsers.length > 0" :org-id="orgId" />
      <iDataTable
        v-if="isAdvertiser"
        :fields="fields"
        :items="advertiserDetails.domains"
        :has-more="false"
        primary-key="advertiser_org_id"
      >
        <template #td(domain)="{data}">
          <iLink :href="getNonRelativeDomain(data.domain)" target="_blank">
            {{ data.domain }}
          </iLink>
        </template>
        <template #td(rate)="{data}">
          {{ getPayoutCellString(data.rate) }}
        </template>
      </iDataTable>
      <iColumn v-if="orgProfilePublications.length > 0" gap="none">
        <iSubHeading>
          Publications
        </iSubHeading>
        <iDataTable
          :fields="publicationFields"
          :items="orgProfilePublications"
          primary-key="publication_id"
          :has-more="false"
        >
          <template #td(domain)="slotProps">
            <iLink :href="getNonRelativeDomain(slotProps.data.domain)" target="_blank">
              {{ slotProps.data.domain }}
            </iLink>
          </template>
          <template #td(actions)="slotProps">
            <ApplyToCollaborate
              apply-button-text="Apply To Collaborate"
              :org-id="orgId"
              :publication-id="slotProps.data.publication_id"
            />
          </template>
        </iDataTable>
      </iColumn>
      <InviteToPublication :org-name="orgProfileData.name" :org-id="orgId" on-platform />
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import { usePartnershipStore } from "@/stores/partnership-store";
import { usePublicationStore } from "@/stores/publication-store";
import InviteToPublication from "@/components/publication/InviteToPublication.vue";
import OrgTeam from "@/components/directory/OrgTeam.vue";
import ApplyToCollaborate from "@/components/publication/ApplyToCollaborate.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";
import AvatarImage from "@/components/AvatarImage.vue";
import { DEFAULT_ORG_LOGO } from "@/constants/publication-constants";

export default {
  name: "OrgProfilePage",
  components: {
    AvatarImage,
    ApplyToCollaborate,
    OrgTeam,
    InviteToPublication,
    PageWrapper,
  },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_COMMUNITY,
      fields: [
        {
          key: "domain",
          label: "Domains",
        },
        {
          key: "rate",
          label: "Rate",
        },
      ],
      publicationFields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "domain",
          label: "Domain",
        },
        {
          key: "status",
          label: "Status",
        },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(useOrganizationStore, [
      "loadingOrgDetails",
      "advertiserDetails",
      "loadingAdvertiserDetails",
      "orgProfileData",
      "hasPublicationCapability",
      "hasAdvertiserCapability",
      "activeUsers",
    ]),
    ...mapState(usePartnershipStore, [
      "advertiserDetails",
      "loadingAdvertiserDetails",
    ]),
    ...mapState(usePublicationStore, [
      "orgProfilePublications",
    ]),
    isAdvertiser() {
      return this.advertiserDetails.domains > 0;
    },
    activeServices() {
      return Object.values(this.orgProfileData.services_offered).filter(service => service.status === "active");
    },
    logoUrl() {
      if (!this.orgProfileData.logo_url) {
        return DEFAULT_ORG_LOGO;
      }
      return this.orgProfileData.logo_url;
    },
    hasExistingConversation() {
      return false;
    },
  },
  created() {
    this.fetchAdvertiserDetails(this.orgId);
  },
  methods: {
    ...mapActions(usePartnershipStore, [
      "fetchAdvertiserDetails",
    ]),
    ...mapActions(useCollaborationStore, [
      "openInviteToPublicationModel",
    ]),
    getNonRelativeDomain(domain) {
      return `//${domain}`;
    },
    formatDomain(domain) {
      if (domain.startsWith("http", 0)) {
        return domain;
      }
      return `https://${domain}`;
    },
    getPayoutCellString(data) {
      if (data.payout_type === "percentage") {
        if (data.payout_value_min === data.payout_value_max) {
          return `${data.payout_value_max}%`;
        } else {
          return `${data.payout_value_min}-${data.payout_value_max}%`;
        }
      }

      if (data.payout_type === "amount") {
        if (data.payout_value_min === data.payout_value_max) {
          return `$${data.payout_value_max}`;
        } else {
          return `$${data.payout_value_min}-${data.payout_value_max}`;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
