import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const usePublicationStore = defineStore("publication-store", {
  state: () => ({
    posts: [],
    post: undefined,
    updatePostIsLoading: false,
    updatePostIsLoadingId: undefined,
    postViewType: "",
    myPublications: [],
    communityPublications: [],
    communityPublicationsLoading: false,
    lastCommunityPublicationId: 0,
    hasMoreCommunityPublications: false,
    publicationId: 0,
    publication: {},
    publicationVersion: {},
    publicationVersionId: 0,
    configuration: {},
    nativePost: { post: {}, loading: false },
    isLoading: false,
    isLoadingConfig: false,
    isLoadingUpdateTheme: false,
    lastPublicationId: 0,
    isInviteOrgToPublicationModalVisible: false,
    isCollaborateModalVisible: false,
    lastInvitationId: 0,
    lastApplicationId: 0,
    collaboratingPublications: [],
    collaboratingPublicationLastId: 0,
    orgProfilePublications: [],
    orgProfilePublicationLastId: 0,
    loadingUpdateDomain: false,
    loadingAddHashtagToPost: false,
    errorUpdateDomain: "",
    errorAddHashtagToPost: "",
    loadingUploadPublicationLogo: false,
    errorUploadPublicationLogo: "",
    uploadPostImageLoading: false,
    versionComparison: {},
    canMarkContentAsExclusive: false,
    updatePostExclusivityIsLoading: false,
  }),
  getters: {
    getAppId() {
      return useGeneralStore().appId;
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    getNativePostContent() {
      return this.nativePost.post.content_split;
    },
    getNativePostTitle() {
      return this.nativePost.post.title;
    },
    getNativePostTags() {
      return this.nativePost.post.post_tags;
    },
    isNativePostLoading() {
      return this.nativePost.loading;
    },
    coreAppApiUrl() {
      return useGeneralStore().getLink(links.coreAppApi);
    },
    orgId() {
      return useGeneralStore().orgId;
    },
    publicationLogo() {
      return this.publication.publication_logo;
    },
    isSignupAndLoginEnabled() {
      return this.configuration.signup_and_login.enabled;
    },
    publicationThemeId() {
      return this.publicationVersion?.theme_id;
    },
  },
  actions: {
    fetchPublications() {
      this.isLoading = true;
      $axios.get(`/publication/org/${this.orgId}/?last_id=${this.lastPublicationId}`)
          .then(response => {
            const newPublications = response.data.publicationData;
            if (newPublications.length > 0) {
              this.myPublications = [...this.myPublications, ...newPublications];
              this.lastPublicationId = newPublications[newPublications.length - 1].publication_id;
            } else {
              this.lastPublicationId = -1;
            }
          })
          .catch(() => {
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    fetchPublication(publicationId) {
      $axios.get(`/publication/${publicationId}`)
          .then(response => {
            this.publication = response.data.publicationData;
            this.publicationConfiguration = response.data.publicationVersionStaging;
            return this.publication;
          })
          .finally(() => {
            this.isLoading = false;
          });

    },
    saveConfiguration(configKey, newConfigData) {
      this.isLoadingConfig = true;
      $axios.put(`/publication/${this.publicationId}/version/${this.publicationVersionId}`, {
        configKey,
        publicationVersionConfig: newConfigData,
      }).then(response => {
        if (response.data.productionVersionId !== this.publicationVersionId) {
          window.location.reload();
        }
      }).finally(() => {
        this.isLoadingConfig = false;
      });
    },
    saveConfigurationFromTemplate(templateConfiguration) {
      this.isLoadingConfig = true;
      $axios.put(`/publication/${this.publicationId}/version/${this.publicationVersionId}/template`, {
        template_configuration: templateConfiguration,
      }).then(response => {
        if (response.data.productionVersionId !== this.publicationVersionId) {
          window.location.reload();
        }
      }).finally(() => {
        this.isLoadingConfig = false;
      });
    },
    createPublication(name, domain, blUid, template, theme = -1) {
      this.isLoading = true;
      $axios.post(`/publication/org/${this.orgId}`, { name, domain, blUid, template, theme }).then(response => {
        if (response.data.publicationId) {
          window.location = `/publication/${response.data.publicationId}`;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    createSubscriptions(subscriptionPlans) {
      this.isLoading = true;
      return $axios.post(`/subscriptions/publication/${this.publicationId}`, {
        subscription_plans: subscriptionPlans,
      }).then(response => response.data).finally(() => {
        this.isLoading = false;
      });
    },
    publishVersion(publicationId, stagingVersionId, memo) {
      $axios.post(`/publication/${publicationId}/version/${stagingVersionId}`, {
        userId: this.getAppId,
        memo: memo,
      }).then(response => {
        if (response.data) {
          window.location.reload();
        }
      });
    },
    fetchNativePostData(blogId, postId) {
      this.nativePost.loading = true;
      $axios.get(`${this.coreAppApiUrl}/v2/posts/info_from_post_ids?blog_id=${blogId}&post_id=${postId}`).then(response => {
        this.nativePost.post = response?.data?.meta?.resolved?.post[`${postId}:${blogId}`];
      }).finally(() => {
        this.nativePost.loading = false;
      });
    },
    setPublication(data) {
      this.publicationId = data.publication.publication_id;
      this.publicationVersionId = data.publicationVersionId;
      this.publicationVersion = data.publicationVersion;
      this.publication = data.publication;
      this.configuration = data.configuration;

      this.setVersionComparisonFields(data.versionComparison);
    },
    setVersionComparisonFields(versionComparison) {
      this.versionComparison = versionComparison;
      this.canMarkContentAsExclusive = this.versionComparison.subscriptions.production.enabled
          || this.versionComparison.subscriptions.staging.enabled;
    },
    setMyPublications(myPublications) {
      this.myPublications = myPublications;
    },
    setPosts(posts) {
      this.posts = posts;
    },
    async uploadPostImage(selectedFile) {
      this.uploadPostImageLoading = true;

      var formData = new FormData();
      formData.append("org_id", this.orgId);
      formData.append("publication_id", this.publicationId);
      formData.append("image", selectedFile);

      return await $axios.post(
        `${this.contentApiV1}/post/image/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      ).then(response => {
        return response.data.imageUrl;
      }).finally(() => {
        this.uploadPostImageLoading = false;
      });
    },
    createPublicationPost(title, html, hashtags, exclusive = false) {
      this.updatePostIsLoading = true;

      $axios.post(
        `${this.contentApiV1}/post/create`,
        {
          publication_id: this.publicationId,
          org_id: this.orgId,
          post_title: title,
          post_content: html,
          post_hashtags: hashtags,
          exclusive: exclusive ? 1 : 0,
        },
      ).then(() => {
        window.location.href = `/publication/${this.publicationId}/posts`;
      }).finally(() => {
        this.updatePostIsLoading = false;
      });
    },
    updatePostExclusiveContentFlag(postId, exclusive) {
      this.updatePostExclusivityIsLoading = true;
      this.apiPost(`${this.contentApiV1}/post/${postId}/update/exclusive`, {
        exclusive: exclusive ? 1 : 0,
      }, () => {
        this.post.exclusive = exclusive;
        this.updatePostExclusivityIsLoading = false;
      }, () => {
        this.updatePostExclusivityIsLoading = false;
      });
    },
    updatePublicationPost(postId, title, html, status) {
      this.updatePostIsLoading = true;
      this.updatePostIsLoadingId = postId;

      $axios.post(
        `${this.contentApiV1}/post/${postId}/update`,
        {
          publication_id: this.publicationId,
          post_title: title,
          post_content: html,
          status: status ?? this.post.status,
        },
      ).then(() => {
        window.location.href = `/publication/${this.publicationId}/posts`;
      }).finally(() => {
        this.updatePostIsLoading = false;
        this.updatePostIsLoadingId = undefined;
      });
    },
    setPost(post) {
      this.post = post;
    },
    fetchApplicationsForPublication(publicationId) {
      this.isLoadingCollaborators = true;
      return $axios.get(`/collaboration/publication/${publicationId}/applications`, {
        params: {
          last_id: this.lastApplicationId,
          limit: 10,
        },
      }).then(response => {
        const newApplications = response.data.applications;
        if (newApplications.length > 0) {
          this.collaboratorApplications = [...this.collaboratorApplications, ...newApplications];
          this.lastApplicationId = newApplications[newApplications.length - 1].publication_applicant_id;
        } else {
          this.lastApplicationId = -1;
        }
        return response.data;
      }).finally(() => {
        this.isLoadingCollaborators = false;
      });
    },
    enableApplications(publicationId) {
      this.isLoading = true;
      return $axios.post(`/publication/${publicationId}/applications/enable`)
          .then(() => {
            this.publication.enable_applications = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    disableApplications(publicationId) {
      this.isLoading = true;
      return $axios.post(`/publication/${publicationId}/applications/disable`)
          .then(() => {
            this.publication.enable_applications = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    saveDomain(domain) {
      this.loadingUpdateDomain = true;

      $axios.put(`/publication/${this.publicationId}/domain`, {
        domain,
      }).then(response => {
        this.publication = response.data.publicationData;
      }).catch(() => {
        this.errorUpdateDomain = "Failed to update the domain";
      }).finally(() => {
        this.loadingUpdateDomain = false;
      });
    },
    getCommunityPublications() {
      if (this.communityPublicationsLoading || !this.hasMoreCommunityPublications) {
        return;
      }

      this.communityPublicationsLoading = true;
      return $axios.get("/publication/public/data", {
        params: {
          last_id: this.lastCommunityPublicationId,
        },
      })
          .then(response => {
            this.communityPublications = [...this.communityPublications, ...response.data.publications];
            this.lastCommunityPublicationId = response.data.nextLastId;
            this.hasMoreCommunityPublications = response.data.hasMore;
          })
          .finally(() => {
            this.communityPublicationsLoading = false;
          });
    },
    updatePublicationTheme(themeId) {
      this.isLoadingUpdateTheme = true;
      return $axios.post(`/publication/${this.publicationId}/theme`, {
        theme_id: themeId,
      }).then(response => {
        this.publication = response.data.publicationData;
      }).finally(() => {
        this.isLoadingUpdateTheme = false;
      });
    },
    uploadPublicationLogo(uploadedPublicationLogo) {
      this.loadingUploadPublicationLogo = true;
      let formData = new FormData();
      formData.append("uploaded_publication_logo", uploadedPublicationLogo);
      return $axios.post(`/publication/${this.publicationId}/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        this.publication = response.data.publicationData;
      }).catch(() => {
        this.errorUploadPublicationLogo = "There was a problem uploading the logo";
      }).finally(() => {
        this.loadingUploadPublicationLogo = false;
      });
    },
    addHashtagsToPost(hashtags) {
      this.loadingAddHashtagToPost = true;
      return $axios.post(
        `${this.contentApiV1}/post/${this.post.post_id}/tag`,
        {
          hashtags,
        },
      ).then(response => {
        this.post.hashtags = response.data.hashtags;
      }).catch(() => {
        this.errorAddHashtagToPost = "Failed to add tag to post";
      }).finally(() => {
        return this.loadingAddHashtagToPost = false;
      });
    },
    removeHashtagFromPost(hashtagId) {
      this.loadingRemoveHashtagFromPost = true;
      return $axios.post(
        `${this.contentApiV1}/post/${this.post.post_id}/untag`,
        {
          hashtag_id: hashtagId,
        },
      ).then(response => {
        this.post.hashtags = response.data.hashtags;
      }).catch(() => {
        this.errorAddHashtagToPost = "Failed to remove tag from post";
      }).finally(() => {
        return this.loadingRemoveHashtagFromPost = false;
      });
    },
  },
});
