import "@bloglovin/vue-component-library/style.css";
import "@/styles/style.scss";
import { createPinia } from "pinia";
import { init, axiosInit, AxiosPiniaPlugin, InjectedDataPlugin, loadFonts } from "@bloglovin/vue-framework";
import pages from "@/pages";
import { useGeneralStore } from "@/stores/general-store";
import { usePublicationStore } from "@/stores/publication-store";
// import { useConfigurationStore } from "@/stores/configuration-store";
import { usePaymentStore } from "@/stores/payments-store";
import { useSocialNetworksStore } from "@/stores/social-networks-store";
import VCL, { useStyleStore } from "@bloglovin/vue-component-library";
import basicTheme from "@/themes/basic-theme";

axiosInit(window.blclient.data.JWT, window.blclient.data.links.api);

const initializationFunction = async function(vuePlugins, piniaDataToBeInjected) {

  const piniaInstance = vuePlugins.pinia;
  for (const storeOrEngine of Object.keys(piniaDataToBeInjected)) {

    if (storeOrEngine.startsWith("use")) {
      let piniaStoreFileName = storeOrEngine.replace(/^use/, "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
      await import(`@/stores/${piniaStoreFileName}`).then(resolvedStoreFile => {
        const storeInstance = resolvedStoreFile[storeOrEngine](piniaInstance);
        storeInstance.$injectCustomProperties(piniaDataToBeInjected[storeOrEngine]);
      });
    } else if (storeOrEngine.endsWith("SmartFeedEngine")) {
      const smartFeedEngineFileName = storeOrEngine.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
      const smartFeedEngineImport = await import(`@/classes/${smartFeedEngineFileName}`);
      const smartFeedEngine = smartFeedEngineImport.default;

      for (const piniaStore of Object.keys(piniaDataToBeInjected[storeOrEngine])) {
        let piniaStoreFileName = piniaStore.replace(/^use/, "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
        await import(`@/stores/smart-feed/feed/${piniaStoreFileName}`).then(resolvedStoreFile => {
          const storeInstance = resolvedStoreFile[piniaStore](piniaInstance);
          storeInstance.handleSmartFeedResponse(piniaDataToBeInjected[storeOrEngine][piniaStore], smartFeedEngine);
        });
      }
    }
  }

  const styleStore = useStyleStore(piniaInstance);
  styleStore.setTheme(basicTheme);

  const generalStore = useGeneralStore(piniaInstance);
  generalStore.setAppId(parseInt(window.blclient.data.appId));
  generalStore.setLinks(window.blclient.data.links);
  generalStore.setBaseDomain(window.blclient.data.baseDomain);

  const socialStore = useSocialNetworksStore(piniaInstance);

  if (window.blclient.data.pageProps["social_networks"]) {
    socialStore.setSocialNetworks(window.blclient.data.pageProps["social_networks"]);
  }

  const publicationStore = usePublicationStore(piniaInstance);
  // const configurationStore = useConfigurationStore(piniaInstance);

  if (window.blclient.data.userId) {
    generalStore.setUserData(window.blclient.data.userData);
    generalStore.setOrgData(window.blclient.data.orgData);
    const paymentStore = usePaymentStore(piniaInstance);
    paymentStore.setDigitalWalletData(window.blclient.data.digitalWallet);
  }
  if (window.blclient.data.publicationData) {
    publicationStore.setPublication(window.blclient.data.publicationData);
  }
  // if (window.blclient.data.configurationData) {
  //   configurationStore.setConfiguration(window.blclient.data.configurationData);
  // }
  if (window.blclient.data.myPublicationsData) {
    publicationStore.setMyPublications(window.blclient.data.myPublicationsData);
  }
};

const pinia = createPinia();
pinia.use(AxiosPiniaPlugin);
pinia.use(InjectedDataPlugin);

const plugins = {
  pinia: pinia,
  vcl: VCL,
};

await loadFonts("Monserrat", [
  "url(https://cdn-alpha.inspiration.co/fonts/MontserratLatin.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/MontserratLatinExt.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/MontserratCyrillic.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/MontserratCyrillicExt.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/MontserratVietnamese.woff2)",
]);

init(process.env.NODE_ENV, process.env.PROJECT_NAME, pages, plugins, initializationFunction);
