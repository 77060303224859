<template>
  <iDropdown
    :show-dropdown="showMenuOptions"
    class="message-dropdown"
    position="right"
    @close="hideMenu"
  >
    <template #trigger>
      <iRow vertical-align="middle" @click="showMenu">
        <iIcon :is-loading="isLoading" icon="ellipsis" />
      </iRow>
    </template>
    <template #overlay>
      <iColumn gap="small">
        <iButton v-if="showEditButton" variant="tertiary" @click="turnOnIsEditing">
          Edit
        </iButton>
        <iButton v-if="!isAuthor" variant="tertiary" @click="showReportsMenu">
          Report
        </iButton>
        <iButton v-if="showBlockButton" variant="tertiary" @click="blockComment">
          Block
        </iButton>
        <iButton v-if="settings.allowsSharing && !isSharing" variant="tertiary" @click="showSocialShareButtons">
          <iIcon icon="arrow-curved-right" />
        </iButton>
        <iButton v-if="showDeleteButton" variant="tertiary" @click="deleteComment">
          Delete
        </iButton>
        <iButton v-if="showUndeleteButton" variant="tertiary" @click="unDeleteComment">
          Restore
        </iButton>
      </iColumn>
      <Teleport to="body">
        <iModal
          :show-footer="false"
          :visible="isShowingReportingOptions"
          title="Report This Message"
          :width="$vssMobile ? 'fill' : 600"
          @blur="closeReportsModal"
          @close="closeReportsModal"
        >
          <template #body>
            <iColumn :ordered="true" type="none">
              <iRow
                v-for="(offense, index) in OFFENSE_OPTIONS"
                :key="index"
              >
                <iButton
                  variant="secondary"
                  @click="report(offense.value)"
                >
                  {{ offense.name }}
                </iButton>
              </iRow>
            </iColumn>
          </template>
        </iModal>
      </Teleport>
    </template>
  </iDropdown>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { OFFENSE_OPTIONS } from "@/constants/conversations-constants";
import { useWindowStore } from "@bloglovin/vue-component-library";

export default {
  name: "ConversationMenu",
  props: {
    messageId: {
      type: Number,
      required: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    isModerator: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    isAuthor: {
      type: Boolean,
      required: true,
    },
    moderationEnabled: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "delete",
    "undelete",
    "block",
    "approve",
    "report",
    "edit",
  ],
  data() {
    return {
      OFFENSE_OPTIONS,
      isSharing: false,
      showMenuOptions: false,
      isShowingReportingOptions: false,
    };
  },
  computed: {
    ...mapState(useWindowStore, [
      "$vssMobile",
    ]),
    ...mapWritableState(useConversationStore, ["loadingMessages"]),
    showBlockButton() {
      return !this.isAuthor && this.isModerator && this.moderationEnabled && this.moderationStatus === "approved";
    },
    showDeleteButton() {
      return this.isAuthor && this.status != "inactive";
    },
    showUndeleteButton() {
      return this.isAuthor && this.status === "inactive";
    },
    showEditButton() {
      return this.settings.allowsEditing && this.isAuthor && this.status === "active";
    },
  },
  created() {
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  methods: {
    turnOnIsEditing() {
      this.hideMenu();
      if (!this.isAuthor) {
        return;
      }
      this.$emit("edit");
    },
    showMenu() {
      this.showMenuOptions = true;
    },
    hideMenu() {
      this.showMenuOptions = false;
    },
    showReportsMenu() {
      this.isShowingReportingOptions = true;
    },

    deleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.hideMenu();
      this.$emit("delete");
    },
    unDeleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.hideMenu();
      this.$emit("undelete");
    },
    report(reason) {
      this.isShowingReportingOptions = false;
      this.hideMenu();
      this.$emit("report", reason);
    },
    closeReportsModal() {
      this.isShowingReportingOptions = false;
    },
    blockComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("block", this.OFFENSE_OPTIONS[0].value);
    },
    showSocialShareButtons() {
      this.isSharing = true;
      this.hideMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
