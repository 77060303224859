// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-card[data-v-614e7a97] {
  padding: var(--614e7a97-\\\$getStyles\\.templateCardPadding);
  border: var(--614e7a97-\\\$getStyles\\.templateCardBorderWidth) solid var(--614e7a97-\\\$getStyles\\.templateCardBorderColor);
  border-radius: var(--614e7a97-\\\$getStyles\\.templateCardBorderRadius);
  overflow: hidden;
}
.template-card-description[data-v-614e7a97] {
  padding: var(--614e7a97-\\\$getStyles\\.templateCardDescriptionPadding);
}
.selected[data-v-614e7a97] {
  background-color: var(--614e7a97-\\\$getStyles\\.templateCardSelectedBackground);
}
.template-card-overlay[data-v-614e7a97] {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
}
.contrast-text[data-v-614e7a97] .i-text {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/CreatePublication.vue","webpack://./CreatePublication.vue"],"names":[],"mappings":"AAEA;EACE,yDAAA;EACA,uHAAA;EACA,oEAAA;EACA,gBAAA;ACDF;ADGA;EACE,oEAAA;ACAF;ADEA;EACE,6EAAA;ACCF;ADCA;EACE,oCAAA;EACA,YAAA;ACEF;ADAA;EACI,YAAA;ACGJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.template-card{\n  padding:v-bind(\"$getStyles.templateCardPadding\");\n  border: v-bind(\"$getStyles.templateCardBorderWidth\") solid v-bind(\"$getStyles.templateCardBorderColor\");\n  border-radius: v-bind(\"$getStyles.templateCardBorderRadius\");\n  overflow: hidden;\n}\n.template-card-description{\n  padding:v-bind(\"$getStyles.templateCardDescriptionPadding\");\n}\n.selected{\n  background-color:v-bind(\"$getStyles.templateCardSelectedBackground\");\n}\n.template-card-overlay{\n  background-color: rgba(0, 0, 0, 50%);\n  height: 100%;\n}\n.contrast-text:deep(.i-text){\n    color:white;\n}\n",".template-card {\n  padding: v-bind(\"$getStyles.templateCardPadding\");\n  border: v-bind(\"$getStyles.templateCardBorderWidth\") solid v-bind(\"$getStyles.templateCardBorderColor\");\n  border-radius: v-bind(\"$getStyles.templateCardBorderRadius\");\n  overflow: hidden;\n}\n\n.template-card-description {\n  padding: v-bind(\"$getStyles.templateCardDescriptionPadding\");\n}\n\n.selected {\n  background-color: v-bind(\"$getStyles.templateCardSelectedBackground\");\n}\n\n.template-card-overlay {\n  background-color: rgba(0, 0, 0, 0.5);\n  height: 100%;\n}\n\n.contrast-text:deep(.i-text) {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
