import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const usePublisherStore = defineStore("publisher-store", {
  state: () => ({
    publisher: {},
    publishers: [],
    affiliateLinks: [],
  }),
  getters: {
    getInstallScript() {
      if (this.publisher.installLink) {
        return `&lt;script type="module" src="${this.publisher.installLink}"&gt;&lt;/script&gt;`;
      } else {
        return null;
      }
    },
    affiliateJsUrl() {
      return useGeneralStore().getLink(links.affiliateJs);
    },
  },
  actions: {
    addPublisherDomain(domain, orgId, ignoreQueryParams = 0) {
      $axios.post("/publisher-domain", JSON.stringify({
        domain,
        ignoreQueryParams,
        orgId,
      }))
          .then(response => {
            this.publisher.domain = response.data?.publisher_domain;
            let time = Date.now();
            this.publisher.installLink =
              `${this.affiliateJsUrl}/src/publisher.js?id=${this.publisher.domain.org_id}&token=${this.publisher.domain.claim_token}&t=${time}`;
            return this.publisher.installLink;
          })
          .catch(error => {
            return { error, message: "could not create tracking link for your domain" };
          });
    },
  },
});
