<template>
  <iRow>
    <iSubHeading>My Publications</iSubHeading>
  </iRow>
  <iRow class="side-nav" height="fill" overflow="scroll">
    <iMenu orientation="vertical" :active-item="activePublicationItem" :items="parsedPublicationNav" />
  </iRow>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { SIDE_NAV_PUBLICATION } from "@/constants/nav-constants";
import { parseUrlVars, injectUrlVars } from "@/helpers/parseUrlVars";
import { useGeneralStore } from "@/stores/general-store";
import _ from "lodash";
export default {
  name: "PublicationSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
    ]),
    ...mapState(usePublicationStore, [
      "publicationId", "myPublications",
    ]),
    activePublicationItem() {
      if (this.publicationId) {
        return `${this.activeItem}_${this.publicationId}`;
      } else if (this.orgId) {
        return `${this.activeItem}_${this.orgId}`;
      } else {
        return this.activeItem;
      }
    },
    parsedPublicationNav() {
      let parsedNav = SIDE_NAV_PUBLICATION;
      let newMenuItems = [];
      SIDE_NAV_PUBLICATION.forEach((menuItem, index) => {
        if (menuItem.type === "menu") {
          let menu = { ...parsedNav.splice(index, 1)[0] };
          this.myPublications.forEach(publication => {
            let updatedMenu = _.cloneDeep(menu);
            let parsedVars = injectUrlVars(updatedMenu, {
              "{publication_name}": publication.name,
              "{publication_label}": publication.name,
              "{org_id}": publication.org_id,
              "{publication_id}": publication.publication_id,
            }, document.location.search, true);
            newMenuItems.push(parsedVars);
          });
        }
      });
      parsedNav = [...parsedNav, ...newMenuItems];
      return parseUrlVars(parsedNav, {
        "{org_id}": this.orgId,
        "{publication_id}": this.publicationId,
      }, document.location.search, true);
    },
  },
};
</script>
