<template>
  <iRow>
    <iSubHeading>Community</iSubHeading>
  </iRow>
  <iRow>
    <iMenu orientation="vertical" :active-item="activeItem" :items="parsedCommunityNav" />
  </iRow>
</template>

<script>
import { SIDE_NAV_COMMUNITY } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
export default {
  name: "CommunitySideNav",
  props: {
    activeItem: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      parsedCommunityNav: parseUrlVars(SIDE_NAV_COMMUNITY),
    };
  },
};
</script>

<style scoped lang="scss"></style>
