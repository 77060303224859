import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";

export const useWaitlistStore = defineStore("waitlist-store", {
  state: () => ({
    brandSurveyBrandName: "",
    brandSurveyReason: "",
    grow_business: "pinia var not in obj",
    waitlistSurveyResponses: {
      grow_business: "pinia var inside an object",
      collaborated: "",
      website: "",
      newsletter: "",
      affiliate_link_platforms: "",
      influencer_marketing_platforms: "",
      digital_products: "",
      sell_subscriptions: "",
      publishing_tool: "",
      ai_content_tools: "",
    },
    oldWaitlistSurveyResponses: {},
    brands: [],
    isLoading: false,
  }),
  getters: {
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    submitWaitlistSurvey() {
      this.apiPost(
        "/survey/waitlist",
        {
          org_id: this.orgId,
          survey_response: JSON.stringify(this.waitlistSurveyResponses),
        },
        () => {
          window.location.href = "/waitlist";
        },
      );
    },
    removeBrand(id) {
      this.apiPost(
        `/survey/brand/${id}/remove`,
        {
          org_id: this.orgId,
        },
        data => {
          this.brands = data.brands;
        },
      );
    },
    submitBrandSurvey() {
      this.apiPost(
        "/survey/brand",
        {
          org_id: this.orgId,
          brand_name: this.brandSurveyBrandName,
          reason: this.brandSurveyReason,
        },
        data => {
          this.brandSurveyReason = "";
          this.brandSurveyBrandName = "";
          this.brands = data.brands;
        },
      );
    },
  },
});
