import { useGeneralStore } from "@/stores/general-store";
import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const usePublicationFeedStore = defineSmartFeedPiniaStore("publication-feed-store", {
  state: () => ({
    entityType: CreatorSmartFeedEngine.PUBLICATION,
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationOrgId: useGeneralStore().orgId,
    },
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `/publication/org/${this.metadata.publicationOrgId}/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
        () => this.metadata.hasMore = false,
      );
    },
  },
});
