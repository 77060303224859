<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_FEATURE_SOCIAL_MEDIA">
    <iHeading>
      Publication Social Media
    </iHeading>
  </PageWrapper>
</template>

<script>
import { PAGE_PUBLICATION_FEATURE_SOCIAL_MEDIA, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationSocialMedia",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_FEATURE_SOCIAL_MEDIA,
    };
  },
};
</script>

<style scoped>

</style>
