export const DEFAULT_PUBLICATION_IMAGE = "/images/logo-default.svg";
export const PUBLICATION_COMMENTS_DEFAULT_CONFIG = {
  enabled: false,
  allowsReply: false,
  allowsLikes: false,
  allowsComments: false,
  allowsUpvoteAndDownvote: false,
  allowsShare: false,
  allowsEditing: false,
  nestedRepliesLimit: 1,
  collapsedReplies: false,
  showReplyReference: false,
  defaultSortingOrder: "Upvotes Descending",
};
