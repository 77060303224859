<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURE_SUBSCRIPTIONS"
    title="Publication Subscriptions"
    :breadcrumbs="breadcrumbs"
    page-name="Subscriptions"
    align="left"
    width="full"
  >
    <iColumn>
      <iToggleSwitch v-model="config.enabled" label="Activate Subscriptions" />
      <iColumn v-if="config.enabled">
        <iColumn class="subscriptions">
          <iRow width="fill" align="between">
            <iColumn>
              <iHeading>
                Subscription Plans
              </iHeading>
            </iColumn>
            <iColumn width="hug">
              <iButton v-if="!isSubscriptionFormOpen" variant="secondary" @click="openSubscriptionForm">
                Add Subscription Plan
              </iButton>
            </iColumn>
          </iRow>
          <iColumn class="subscription-plans">
            <iRow gap="extraLarge">
              <iColumn
                v-for="(plan, index) in config.plans"
                :key="index"
                width="hug"
              >
                <iStyledCard
                  :width="300"
                  max-width-only
                  :title="plan.name"
                  :subtitle="planSubtitle(plan)"
                >
                  <template #footer>
                    <iRow>
                      <iToggleSwitch v-model="plan.enabled" label="Enabled" />
                    </iRow>
                  </template>
                </iStyledCard>
              </iColumn>
            </iRow>
          </iColumn>
          <iColumn v-if="newSubscriptions.length > 0" class="subscription-plans">
            <iHeading font-size="extraLarge">
              New Subscriptions
            </iHeading>
            <iRow gap="extraLarge">
              <iColumn
                v-for="(plan, index) in newSubscriptions"
                :key="index"
                width="hug"
              >
                <iStyledCard
                  :width="300"
                  max-width-only
                  :title="plan.name"
                  :subtitle="`${plan.currency_code.symbol}${plan.price} ${plan.billing_frequency.name}`"
                >
                  <template #footer>
                    <iRow>
                      <iToggleSwitch v-model="plan.enabled" label="Enabled" />
                    </iRow>
                  </template>
                </iStyledCard>
              </iColumn>
            </iRow>
          </iColumn>
        </iColumn>
      </iColumn>
      <iSpace :height="10" />
      <iButton :is-loading="isLoading" @click="savePublicationConfig">
        Save
      </iButton>
    </iColumn>
    <iModal :visible="isSubscriptionFormOpen" title="Add a new Subscription" @close="closeSubscriptionForm">
      <template #body>
        <iColumn width="fill">
          <iTextInput v-model="subscriptionForm.name" label="Subscription Name" />
          <iTextArea v-model="subscriptionForm.description" label="Subscription Descripion" />
          <iSelect
            id="billing-frequency"
            v-model="subscriptionForm.billing_frequency"
            label="Billing Frequncy"
            :items="subscriptionTypes"
            value-field="name"
            text-field="name"
          />
          <iTextInput v-model="subscriptionForm.price" type="number" label="Price" />
          <!-- <iSelect
            id="currency-code"
            v-model="subscriptionForm.currency_code"
            label="Currency"
            :items="currencyCodes"
            value-field="name"
            text-field="symbol"
          /> -->
          <!-- <iTextInput v-model="subscriptionForm.exclusivity_window_days" type="number" label="Exclusivity window" /> -->
        </iColumn>
      </template>
      <template #footer>
        <iRow width="fill" align="between">
          <iColumn width="hug">
            <iButton variant="secondary" @click="closeSubscriptionForm">
              Cancel
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton @click="addSubscriptionPlan">
              Submit
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_FEATURE_SUBSCRIPTIONS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationSubscriptions",
  components: {
    PageWrapper,
  },
  props: {
    subscriptionPlans: {
      type: Array,
      required: false,
      default: () => [],
    },
    validBillingFrequencies: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURE_SUBSCRIPTIONS,
      SECTION_PUBLICATIONS,
      config: {
        enabled: false,
      },
      isSubscriptionFormOpen: false,
      subscriptionForm: {
        name: "",
        description: "",
        billing_frequency: { name: "weekly", id: "weekly" },
        price: 0,
        exclusivity_window_days: 0,
        currency_code: MAPPED_CURRENCY_CODES[0],
      },
      newSubscriptions: [],
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["configuration", "isLoading", "orgId", "publicationId"]),
    subscriptionTypes() {
      return this.validBillingFrequencies.map(key => ({ name: key, id: key }));
    },
    currencyCodes() {
      return MAPPED_CURRENCY_CODES;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/features`, label: "Features" },
      ];
    },
  },
  created() {
    this.config = { ...this.config, ...this.configuration.subscriptions };
    if (this.config.enabled) {
      this.config.plans = this.config.plans.map(plan => {
        let planData = this.subscriptionPlans.find(subscriptionPlan => subscriptionPlan.subscription_plan_id == plan.subscription_plan_id);
        return {
          ...plan,
          ...planData,
          billing_frequency: this.subscriptionTypes.find( frequency => frequency.id == planData.billing_frequency),
          currency_code: this.currencyCodes.find( code => code.key == planData.currency_code),
        };
      });
    }
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration", createSubscriptions: "createSubscriptions" }),
    async savePublicationConfig() {
      let newSubscriptions = this.newSubscriptions.map(plan => ({
        ...plan,
        billing_frequency: plan.billing_frequency.name,
        currency_code: plan.currency_code.key,
      }));
      let subscriptionIds = await this.createSubscriptions(newSubscriptions);
      if (subscriptionIds.subscriptionData) {
        newSubscriptions.forEach((sub, index) => {
          sub.subscription_plan_id = subscriptionIds.subscriptionData[index];
        });
      }
      if (this.config.enabled && !this.config.plans) {
        this.config.plans = [];
      }
      this.config.plans = [...this.config.plans, ...newSubscriptions];
      let subscriptionConfig = { ...this.config };
      subscriptionConfig.plans = this.config.plans.map(plan => ({ enabled: plan.enabled, subscription_plan_id: plan.subscription_plan_id }));
      this.newSubscriptions = [];
      this.saveConfiguration("subscriptions", subscriptionConfig);
    },
    planSubtitle(plan) {
      let string = "";
      if (plan.currency_code?.symbol) {
        string += `${plan.currency_code.symbol}`;
      }
      if (plan.price) {
        string += `${plan.price} `;
      }
      if (plan.billing_frequency?.name) {
        string += ` ${plan.billing_frequency.name}`;
      }
      return string;
    },
    openSubscriptionForm() {
      this.isSubscriptionFormOpen = true;
    },
    closeSubscriptionForm() {
      this.isSubscriptionFormOpen = false;
    },
    async addSubscriptionPlan() {
      let deleteIndex = this.newSubscriptions.findIndex(plan => plan.billing_frequency === this.subscriptionForm.billing_frequency);
      if (deleteIndex >= 0 ) {
        this.newSubscriptions.splice(deleteIndex, 1);
      }
      this.newSubscriptions.push({
        ...this.subscriptionForm,
        price: parseFloat(this.subscriptionForm.price),
        access_revocation_grace_period_days: 5,
        exclusivity_window_days: parseInt(this.subscriptionForm.exclusivity_window_days),
        enabled: false,
      });
      this.closeSubscriptionForm();
    },
  },
};
</script>

<style scoped>
</style>
