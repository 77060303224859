<template>
  <Chatroom
    v-if="isChatroomLayout"
    :context="context"
    :entity-id="entityId"
    :conversation-id="conversationId"
  />
  <Comments
    v-else
    :context="context"
    :entity-id="entityId"
    :conversation-id="conversationId"
  />
</template>

<script>

import { mapState } from "pinia";
import { Enum } from "@bloglovin/vue-component-library";
import Comments from "@/components/conversations/Comments";
import Chatroom from "@/components/conversations/Chatroom";
import { useConversationStore } from "@/stores/conversation-store";

export default {
  name: "Conversation",
  components: {
    Chatroom,
    Comments,
  },
  props: {
    layout: {
      type: Enum,
      required: true,
      options: ["chatroom", "comments"],
    },
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState(useConversationStore, ["conversation"]),
    isChatroomLayout() {
      return this.layout === "chatroom";
    },
  },
};
</script>
