<template>
  <iColumn width="fill">
    <iText>{{ entity.affiliateLink }}</iText>
    <iText>{{ entity.advertiserName }}</iText>
  </iColumn>
</template>
<script>

export default {
  name: "AffiliateLinkListItem",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
};
</script>

