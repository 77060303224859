<template>
  <iStyledCard
    :splash-image="publicationSplashImage"
    :badge="mappedPublicationStatus"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/publication/${publication.publication_id}`"
    :metrics="publication.metrics"
  >
    <template #title>
      <iRow vertical-align="middle" wrap="nowrap">
        <iImageContainer
          object-fit="cover"
          :height="35"
          :width="35"
          shape="circle"
          :src="publicationLogo"
        />
        <iSubHeading font-weight="dark">
          {{ publication.name }}
        </iSubHeading>
      </iRow>
    </template>
    <template #subtitle>
      <iLink
        hover-underline
        color="subtle"
        target="_blank"
        :href="subTitleHref"
      >
        {{ subTitle }}
      </iLink>
    </template>
    <template v-if="description" #description>
      <iLink hover-underline target="_blank" :href="descriptionHref">
        {{ description }}
      </iLink>
      <iSpace :height="5" />
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";
export default {
  name: "PublicationCard",
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewDropdown: false,
      DEFAULT_ORG_LOGO,
      DEFAULT_SPLASH_IMAGE,
    };
  },
  computed: {
    mappedPublicationStatus() {
      switch (this.publication.status) {
        case "active":
          return "Live";
        case "draft":
          return "Draft";
        default:
          return "Offline";
      }
    },
    subTitle() {
      return this.publication.domain ?? `${window.location.origin}/my-publication/${this.publication.publication_id}`;
    },
    subTitleHref() {
      return `${window.location.origin}/my-publication/${this.publication.publication_id}`;
    },
    description() {
      if (this.publication.staged_changes) {
        return `${this.publication.staged_changes} unpublished changes on staging.`;
      }
      return "";
    },
    descriptionHref() {
      return `${window.location.origin}/my-publication/staging/${this.publication.publication_id}`;
    },
    publicationLogo() {
      if (!this.publication.publication_logo) {
        return this.DEFAULT_ORG_LOGO;
      }
      return this.publication.publication_logo;
    },
    publicationSplashImage() {
      if (!this.publication.splash_image) {
        return this.DEFAULT_SPLASH_IMAGE;
      }
      return this.publication.splash_image;
    },
  },
  methods: {
    snakeToTitle,
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
  }),
};

</script>
<style scoped lang="scss">
.publication-card{
  border:v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details{
  padding-top:v-bind("$getStyles.paddingTop");
  padding-left:v-bind("$getStyles.paddingLeft");
  padding-right:v-bind("$getStyles.paddingRight");
  padding-bottom:v-bind("$getStyles.paddingBottom");
}
</style>
