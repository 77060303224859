<template>
  <div
    class="logo"
    @click="onClick"
    @touchstart.prevent="startPressAndHold"
    @touchend.prevent="cancelPressAndHold"
  >
    <img class="logo full" :src="imageFullSrc" alt="logo">
    <img class="logo tablet" :src="imageMobileSrc" alt="logo">
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    height: {
      type: String,
      required: false,
      default: "60px",
    },
    width: {
      type: String,
      required: false,
      default: "unset",
    },
  },
  data() {
    return {
      clickCount: 0,
      pressTimer: null,
    };
  },
  computed: {
    imageMobileSrc() {
      const images = [
        "/images/logo/windmill-gradient-v2.svg",
        "/images/logo/starfish-gradient.svg",
        "/images/logo/spiral-gradient.svg",
      ];
      return images.at(this.clickCount % 3);
    },
    imageFullSrc() {
      const images = [
        "/images/logo/windmill-gradient-text-v2.svg",
        "/images/logo/starfish-gradient-text.svg",
        "/images/logo/spiral-gradient-text.svg",
      ];
      return images.at(this.clickCount % 3);
    },
    myDisplayMobileLogo() {
      if (this.clickCount % 3 === 0) {
        return "inline";
      }
      return "none";
    },
  },
  methods: {
    onClick(event) {
      if (event.altKey) {
        this.clickCount++;
      } else {
        window.location.href = "/";
      }
    },
    startPressAndHold() {
      this.pressTimer = setTimeout(() => {
        this.clickCount++;
      }, 2000);
    },
    cancelPressAndHold() {
      clearTimeout(this.pressTimer);
      this.pressTimer = null;
    },
  },
};
</script>

<style scoped lang="scss">

.logo {
  height: v-bind(height);
  cursor: pointer;
  display: flex;
  align-items: center;

  .image {
    height: 100%;
    object-fit: contain;
  }

  &.full {
    display: inline;
    margin-bottom: -4px;
    padding-left: 8px;
  }
  &.tablet {
    height: 50px;
    display: none;
  }

  @include tablet {
    &.tablet {
      display: inline;
    }
    &.full {
      display: none;
    }
  }
}
</style>
