<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_FEATURE_SHOP">
    <iHeading>
      Publication Shop
    </iHeading>
  </PageWrapper>
</template>

<script>
import { PAGE_PUBLICATION_FEATURE_SHOP, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationShop",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_FEATURE_SHOP,
    };
  },
};
</script>

<style scoped>

</style>
