export const PUBLICATION_TEMPLATES = {
  Blog: {
    name: "Blog",
    description: "Personal or commercial blogging",
    configuration: {
      layout: {
        name: "Blog",
        description: "Personal or commercial blogging",
      },
      community: {
        enabled: false,
        allowsReply: false,
        allowsLikes: false,
        allowsComments: false,
        allowsUpvoteAndDownvote: false,
        allowsShare: false,
        allowsEditing: false,
        nestedRepliesLimit: 2,
        collapsedReplies: false,
        showReplyReference: false,
        defaultSortingOrder: "Upvotes Descending",
        topics: [
          {
            name: "miscellaneous",
            label: "Misc.",
            actionValue: "/miscellaneous",
            type: "static",
          },
        ],
      },
      subscriptions: { enabled: false },
    },
    availability: "available",
  },
  Community: {
    name: "Community",
    description: "Community driven with upvoting and downvoting for conversations and replies",
    configuration: {
      layout: {
        name: "Community",
        description: "Community driven with upvoting and downvoting for conversations and replies",
      },
      signup_and_login: { enabled: false },
      post_creation: {
        enabled: true,
        post_creation_type: "quick_post",
      },
      community: {
        enabled: false,
        allowsReply: false,
        allowsLikes: false,
        allowsComments: false,
        allowsUpvoteAndDownvote: false,
        allowsShare: false,
        allowsEditing: false,
        nestedRepliesLimit: 2,
        collapsedReplies: false,
        showReplyReference: false,
        defaultSortingOrder: "Upvotes Descending",
        topics: [
          {
            name: "miscellaneous",
            label: "Misc.",
            actionValue: "/miscellaneous",
            type: "static",
          },
        ],
      },
      moderation: {
        allowModeraters: true,
      },
      subscriptions: { enabled: false },
    },
    availability: "available",
  },
  ImageSharing: {
    name: "Image Sharing",
    description: "Image sharing with ability for followers to like and share content",
    configuration: {
      layout: {
        name: "Image Sharing",
        description: "Image sharing with ability for followers to like and share content",
      },
      signup_and_login: { enabled: true },
      community: {
        enabled: false,
        allowsReply: false,
        allowsLikes: false,
        allowsComments: false,
        allowsUpvoteAndDownvote: false,
        allowsShare: false,
        allowsEditing: false,
        nestedRepliesLimit: 1,
        collapsedReplies: false,
        showReplyReference: false,
        defaultSortingOrder: "Latest",
      },
      subscriptions: { enabled: true },
    },
    availability: "pending",
  },
  MiniBlog: {
    name: "Mini Blogs",
    description: "Short-form blogging with ability for community to interact",
    configuration: {
      layout: {
        name: "Mini Blogs",
        description: "Short-form blogging with ability for community to interact",
      },
      signup_and_login: { enabled: false },
      community: {
        enabled: false,
        allowsReply: false,
        allowsLikes: false,
        allowsComments: false,
        allowsUpvoteAndDownvote: false,
        allowsShare: false,
        allowsEditing: false,
        nestedRepliesLimit: 1,
        collapsedReplies: false,
        showReplyReference: true,
        defaultSortingOrder: "Latest",
      },
      subscriptions: { enabled: false },
    },
    availability: "pending",
  },
};
