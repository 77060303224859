<template>
  <PageWrapper
    :active-page="$options.name"
    active-section=""
    :title="`Hi ${userData.first_name.charAt(0).toUpperCase().concat(userData.first_name.slice(1))}!`"
    sub-title="Welcome to inspiration.co"
    :breadcrumbs="[]"
    page-name="Home"
    align="center"
    vertical-align="middle"
    width="fixed"
    content-height="hug"
  >
    <iColumn>
      <iRow>
        <iParagraph>
          We are reviewing your application to join our exclusive beta launch.
        </iParagraph>
      </iRow>

      <iRow>
        ⏰
        <iText>
          WHILE YOU WAIT ({{ tasksCompleted }}/5)
        </iText>
      </iRow>

      <iRow>
        <iText>
          Tell us more about you and increase your chances of being selected for our closed beta!
        </iText>
      </iRow>

      <iRow align="left">
        <WaitlistCard
          v-for="(card, index) in cardDetails"
          :key="index"
          :class="`${card.clss}`"
          :icon="card.icon"
          @click="card.action"
        >
          <iRow wrap="nowrap" vertical-align="top" align="between">
            <iText>
              {{ card.title }}
            </iText>
            <iIcon icon="caret-right" class="fixed-icon-size" size="small" />
          </iRow>
          <iRow v-if="card.subtitle" vertical-align="middle">
            <iText variant="subtle">
              {{ card.subtitle }}
            </iText>
          </iRow>
        </WaitlistCard>
      </iRow>

      <iSpace :height="80" />
    </iColumn>
  </PageWrapper>
</template>

<script>
import WaitlistCard from "@/components/waitlist/WaitlistCard";
import PageWrapper from "@/components/PageWrapper";
import { mapState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { socialNetworkConnectionStatus } from "@/enums/social-network-enums";

export default {
  name: "Waitlist",
  components: {
    PageWrapper,
    WaitlistCard,
  },
  props: {
    invitationCount: {
      type: Number,
      required: false,
      default: 0,
    },
    completedBrandSurvey: {
      type: Boolean,
      required: false,
      default: false,
    },
    completedWaitlistSurvey: {
      type: Boolean,
      required: false,
      default: false,
    },
    socialNetworks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      connectedSocials: 0,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userData", "orgId"]),
    tasksCompleted() {
      let completedTaskCount = 0;
      if (this.connectedSocials > 0) {
        completedTaskCount++;
      }

      if (this.invitationCount > 0) {
        completedTaskCount++;
      }

      if (this.completedBrandSurvey) {
        completedTaskCount++;
      }

      if (this.completedWaitlistSurvey) {
        completedTaskCount++;
      }

      return completedTaskCount;
    },
    cardDetails() {
      return [
        {
          title: "Connect socials",
          subtitle: `Connected: ${this.connectedSocials}`,
          icon: "like",
          clss: "social-accounts",
          action: this.onConnectSocials,
        },
        {
          title: "Fill out a quick survey",
          icon: "document",
          clss: "survey",
          action: this.onFillSurvey,
        },
        {
          title: "Invite friends",
          subtitle: `My invites: ${this.invitationCount}`,
          icon: "community",
          clss: "friends",
          action: this.onInviteClick,
        },
        {
          title: "Book a chat with our team",
          icon: "calender",
          clss: "contact-us",
          action: this.onBookChat,
        },
        {
          title: "Tell us about your favorite brands",
          icon: "shopping-bag",
          clss: "brands",
          action: this.onBrandSurveyClick,
        },
      ];
    },
  },
  mounted() {
    this.getConnectedSocials();
  },
  methods: {
    onConnectSocials() {
      window.location.href = `/settings/org/${this.orgId}/social-networks`;
    },
    onFillSurvey() {
      window.location.href = "/waitlist/survey";
    },
    getConnectedSocials() {
      const connectedStatuses = [socialNetworkConnectionStatus.CONNECTED, socialNetworkConnectionStatus.MANUAL_CONNECTION];
      this.socialNetworks.forEach(socialNetwork => {
        if (connectedStatuses.includes(socialNetwork.status)) {
          this.connectedSocials++;
        }
      });
    },
    onBookChat() {
      window.open("https://calendly.com/nick-inspiration/30min", "_blank");
    },
    onInviteClick() {
      window.location.href = "/waitlist/invite";
    },
    onBrandSurveyClick() {
      window.location.href = "/waitlist/brand-survey";
    },
  },
};
</script>

<style scoped lang="scss">
.social-accounts {
  :deep(.i-card-header) {
    background-color: #5E26FF;
  }
}

.survey {
  :deep(.i-card-header) {
    background-color: #2663FF;
  }
}

.friends {
  :deep(.i-card-header) {
    background-color: #C301C7;
  }
}

.contact-us {
  :deep(.i-card-header) {
    background-color: #1DC701;
  }
  :deep(svg){
    width: 22px;
  }
}

.brands {
  :deep(.i-card-header) {
    background-color: #FCBD1C;
  }
  :deep(svg){
    width: 26px;
  }
}
</style>
