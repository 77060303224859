<template>
  <iInfiniteScroll
    :has-more="hasMoreMessages"
    :is-loading="loadingMessages"
    :scroll-threshold="0"
    class="chatroom"
    scroll-direction="top"
    @load-more="loadMore"
  >
    <iSpace :height="5" />
    <iRow
      v-for="(message, index) in invertedMessages"
      v-show="invertedMessages.length"
      :key="index"
      width="fill"
    >
      <ActiveCommentWidget
        :author-avatar="message.avatarUrl"
        :author-id="message.userId"
        :author-name="message.authorName"
        :conversation-id="message.conversationId"
        :date-sent="message.timestampCreated"
        :date-updated="message.timestampUpdated"
        :message="message.message"
        :message-id="message.messageId"
        :moderation-score="message.moderationScore"
        :moderation-status="message.moderationStatus"
        :num-approved-replies="message.numApprovedReplies"
        :num-likes="message.numLikes"
        :num-reports="message.numReports"
        :replies="message.replies ? message.replies : []"
        :status="message.status"
        :user-down-voted="message.userDownVoted"
        :user-liked="message.userLiked"
        :user-up-voted="message.userUpVoted"
        :vote-score="message.voteScore"
      />
    </iRow>
  </iInfiniteScroll>
  <iRow v-show="!invertedMessages.length">
    <iText variant="subtle">
      No messages yet...
    </iText>
  </iRow>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import ActiveCommentWidget from "@/components/conversations/ActiveCommentWidget";

export default {
  name: "ChatroomThread",
  components: {
    ActiveCommentWidget,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "comment",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      newComment: "",
      currentSortingOption: "by_date_sent",
    };
  },
  computed: {
    ...mapState(useConversationStore, ["messages", "sortStrategy", "loadingMessages", "hasMoreMessages"]),
    invertedMessages() {
      return this.messages.slice().reverse();
    },
    hasMessages() {
      return this.messages.length > 0;
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      fetchMore: "fetchPublicationChatroom",
      deleteComment: "deleteComment",
      submitComment: "submitComment",
    }),
    editComment(commentData) {
      this.submitEditComment(commentData);
    },
    loadMore() {
      this.fetchMore(this.entityId);
    },
  },
};
</script>

<style lang="scss" scoped>
.chatroom {
  width: 100%;
}

:deep(.infinite-scroll) {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-end;
}

:deep(.v3-infinite-loading) {
  height: unset;
}
</style>
