// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-45e3bb46] .user-avatar img {
  border-radius: var(--45e3bb46-\\\$getStyles\\.borderRadius);
}
.header-wrapper[data-v-45e3bb46] {
  padding-left: var(--45e3bb46-\\\$getStyles\\.avatarOffset);
}
.message-avatar[data-v-45e3bb46] {
  position: relative;
  top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/conversations/ConversationHeader.vue","webpack://./ConversationHeader.vue"],"names":[],"mappings":"AAEA;EACE,wDAAA;ACDF;ADIA;EACE,uDAAA;ACDF;ADIA;EACE,kBAAA;EACA,QAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.user-avatar img) {\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.header-wrapper {\n  padding-left: v-bind(\"$getStyles.avatarOffset\");\n}\n\n.message-avatar {\n  position: relative;\n  top: 4px;\n}\n",":deep(.user-avatar img) {\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.header-wrapper {\n  padding-left: v-bind(\"$getStyles.avatarOffset\");\n}\n\n.message-avatar {\n  position: relative;\n  top: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
