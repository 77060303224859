<template>
  <iModal :visible="visible" :title="title" @close="closeDetails">
    <template #body>
      <iRow class="modal-content-container">
        <iRow v-for="(config, configIndex) in templateSummary" :key="configIndex">
          <iColumn width="fill">
            <iHeading variant="subtle">
              {{ camelToTitleCase(config.title) }}
            </iHeading>
            <iRow>
              <iRow v-if="config.title === 'layout'">
                <iRow
                  v-for="(feature, featureIndex) in config.features"

                  :key="featureIndex"
                  width="fill"
                >
                  <iRow>
                    <iText>
                      {{ camelToTitleCase(feature.feature) }}
                      <iText v-if="feature.value && feature.value !== true">
                        :
                      </iText>
                    </iText>
                    <iText v-if="feature.value && feature.value !== true" variant="subtle">
                      {{ feature.value }}
                    </iText>
                  </iRow>
                </iRow>
              </iRow>
              <iRow
                v-for="(feature, featureIndex) in config.features"
                v-else
                :key="featureIndex"
                width="hug"
                class="chip-container"
              >
                <iText>
                  {{ camelToTitleCase(feature.feature) }}
                  <iText v-if="feature.value && feature.value !== true">
                    :
                  </iText>
                </iText>
                <iText v-if="feature.value && feature.value !== true" variant="subtle">
                  {{ feature.value }}
                </iText>
              </iRow>
            </iRow>
          </iColumn>
        </iRow>
      </iRow>
    </template>
    <template #footer>
      <iSpace :height="3" />
      <iButton @click="closeDetails">
        Close
      </iButton>
    </template>
  </iModal>
</template>

<script>

import { camelToTitleCase } from "@bloglovin/vue-framework";

export default {
  name: "TemplateDetails",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    visible: {
      type: Boolean,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeDetails"],
  computed: {
    templateSummary() {
      const items = this.excludeDisabledConfigurations(["version", "navigation"]).map(config => (
        {
          title: config,
          features: this.featureSummary(this.template[config]),
        }
      ));
      return items.filter(item => item.features.length > 0);
    },
  },
  methods: {
    camelToTitleCase,
    excludeDisabledConfigurations( excluding) {
      return Object.keys(this.template).filter(config => {
        return this.template[config] !== false && !excluding.includes(config);
      });
    },
    closeDetails() {
      this.$emit("closeDetails");
    },
    excludeDisabledFeatures(features) {
      return features.filter(({ feature, value }) => value && feature !== "enabled" && typeof value !== "object");
    },
    featureSummary(features) {
      return this.excludeDisabledFeatures( Object.keys(features).map( feature => {
        return {
          feature,
          value: features[feature],
        };
      }));
    },
  },
  styleGuide: () => ({
    chipBorderRadius: { "size.borderRadius": "large" },
    chipBorderColor: { "color.font": "subtle" },
    chipPaddingHorizontal: { "size.spacing": "standard" },
    chipPaddingVertical: { "size.spacing": "small" },
  }),
};
</script>

<style scoped>
:deep(.modal-body) {
  height: 50vh;
  overflow-y: auto;
}

.chip-container {
  border-radius: v-bind('$getStyles.chipBorderRadius');
  border: 1px solid v-bind('$getStyles.chipBorderColor');
  padding-inline: v-bind('$getStyles.chipPaddingHorizontal');
  padding-block: v-bind('$getStyles.chipPaddingVertical');
}
</style>
