<template>
  <iImageContainer
    class="avatar"
    :src="myAvatarUrl"
    alt="avatar"
    shape="circle"
    object-fit="cover"
  />
</template>

<script>
export default {
  name: "AvatarImage",
  props: {
    size: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    border: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    myAvatarUrl() {
      if (this.src === "") {
        return "/images/avatar-default.png";
      }
      return this.src;
    },
    borderStyle() {
      if (this.border) {
        return "1px solid rgba(0, 0, 0, 0.15)";
      }
      return "none";
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  border-radius: 100%;
  height: v-bind(size);
  width: v-bind(size);
  border: v-bind(borderStyle);
}
</style>
