<template>
  <SmartFeedTableRow :entity="entity" :fields="fields">
    <template #td(affiliateLink)>
      <iLink :href="entity.affiliateLink">
        {{ entity.affiliateLink }}
      </iLink>
    </template>
    <template #td(originalLink)>
      <iText>
        {{ entity.originalLink }}
      </iText>
    </template>
    <template #td(myDescription)="{index}">
      <iTextEdit
        :width="200"
        :value="entity.myDescription"
        placeholder="Add a description"
        variant="locked"
        show-as-text
        @edit="editingDescription = $event"
        @submit="(value) => saveLinkDescription(entity, index, value)"
      />
    </template>
    <template #td(advertiserName)>
      {{ entity.advertiserName }}
    </template>
    <template #td(brandName)="{index}">
      <iTextEdit
        :width="200"
        :value="entity.brandName"
        variant="locked"
        placeholder="Add a brand name"
        show-as-text
        @edit="editingBrand = $event"
        @submit="(value) => saveBrandName(entity, index, value)"
      />
    </template>
    <template #td(productName)="{index}">
      <iTextEdit
        :width="200"
        :value="entity.productName"
        variant="locked"
        placeholder="Add a product name"
        show-as-text
        @edit="editingProductName = $event"
        @submit="(value) => saveProductName(entity, index, value)"
      />
    </template>
    <template #td(pendingEarnings)>
      <template v-if="entity.pendingEarnings">
        {{
          getFormattedCurrencyValue(
            entity.currency,
            entity.pendingEarnings
          )
        }}
      </template>
      <template v-else>
        -
      </template>
    </template>
    <template #td(lockedEarnings)>
      <template v-if="entity.lockedEarnings">
        {{
          getFormattedCurrencyValue(
            entity.currency,
            entity.lockedEarnings
          )
        }}
      </template>
      <template v-else>
        -
      </template>
    </template>
    <template #td(totalEarnings)>
      <template
        v-if="entity.totalEarnings"
      >
        {{
          getFormattedCurrencyValue(
            entity.currency,
            entity.totalEarnings
          )
        }}
      </template>
      <template v-else>
        -
      </template>
    </template>
  </SmartFeedTableRow>
</template>
<script>

import SmartFeedTableRow from "@/components/smart-feed/SmartFeedTableRow";

export default {
  name: "AffiliateLinkTableRow",
  components: { SmartFeedTableRow },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
};
</script>

