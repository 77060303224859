<template>
  <iRow wrap="nowrap">
    <iSpace :width="10" />
    <iColumn class="card">
      <NewReply
        context="reply"
        :entity-id="messageId"
        :conversation-id="conversationId"
      />
      <iRow
        v-for="(reply, index) in replies"
        :key="index"
        :class="{deleted: reply.status === 'inactive'}"
        width="fill"
        class="message-container"
      >
        <ConversationHeader
          :date="reply.timestampCreated"
          :author-name="reply.authorName"
          :author-avatar="message.avatarUrl"
          :author-id="reply.userId"
        >
          <template #content>
            <MessageFlags
              :status="reply.status"
              :moderation-status="reply.moderationStatus"
              :timestamp-updated="reply.timestampUpdated"
            />
            <iRow>
              <iColumn v-if="isEditing && editingId === reply.messageId">
                <iTextInput
                  v-model="newCommentReply"
                  width="fill"
                  name="editComment"
                />
                <iRow align="right">
                  <iColumn width="hug">
                    <iButton @click="saveEdit">
                      Save
                    </iButton>
                  </iColumn>
                  <iColumn width="hug">
                    <iButton @click="cancelEditing">
                      Cancel
                    </iButton>
                  </iColumn>
                </iRow>
              </iColumn>
              <iColumn v-else>
                <iText>
                  {{ reply.message }}
                </iText>
              </iColumn>
            </iRow>
            <iColumn>
              <iText v-if="reply.userId == userId && reply.moderationStatus == 'unknown'" variant="subtle">
                Not visible, pending moderation.
              </iText>
              <iText v-if="reply.userId == userId && reply.moderationStatus == 'blocked'" variant="subtle">
                This message has been blocked.
              </iText>
              <iText v-if="errorMessage" variant="subtle">
                {{ errorMessage }}
              </iText>
              <iRow v-if="isModerator && moderationEnabled" align="between">
                <iText variant="accent">
                  Report Count: {{ reply.numReports }}
                </iText>
                <iText variant="accent">
                  moderation score: {{ reply.moderationScore }}
                </iText>
              </iRow>
            </iColumn>
            <ConversationFooter
              :current-message="reply.message"
              :author-id="reply.userId"
              :message-id="reply.messageId"
              :vote-score="reply.voteScore"
              :num-replies="reply.numApprovedReplies"
              :num-likes="reply.numLikes"
              :moderation-status="reply.moderationStatus"
              :status="reply.status"
              :user-liked="reply.userLiked"
              :user-up-voted="reply.userUpVoted"
              :user-down-voted="reply.userDownVoted"
              :allow-replying="false"
              @start-editing="turnOnIsEditing(reply.messageId, reply.message)"
              @report="report(reply)"
              @approve="approve(reply.messageId)"
              @escalate="escalate(reply.messageId)"
              @block="block(reply.messageId)"
              @delete="deleteComment(reply.messageId)"
              @undelete="unDeleteComment(reply.messageId)"
              @like="like(reply.messageId)"
              @unlike="unlike(reply.messageId)"
              @upvote="upVote(reply.messageId)"
              @revokeupvote="revokeUpVote(reply.messageId)"
              @downvote="downVote(reply.messageId)"
              @revokedownvote="revokeDownVote(reply.messageId)"
            />
          </template>
        </ConversationHeader>
      </iRow>
    </iColumn>
  </iRow>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { useGeneralStore } from "@/stores/general-store";
import ConversationFooter from "@/components/conversations/ConversationFooter";
import ConversationHeader from "@/components/conversations/ConversationHeader";
import MessageFlags from "@/components/conversations/MessageFlags";
import NewReply from "@/components/conversations/NewReply";

export default {
  name: "ReplyWidget",
  components: {
    ConversationHeader,
    ConversationFooter,
    MessageFlags,
    NewReply,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    replies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      editingId: 0,
      isReplying: false,
      newCommentReply: "",
      showMenuOptions: false,
      showReportOptions: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userId"]),
    ...mapState(useConversationStore, ["errorMessage", "isModerator", "moderationEnabled"]),
    isAuthor() {
      return this.userId === this.userId;
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      editMessage: "editMessage",
      likeMessage: "likeMessage",
      deleteMessage: "deleteMessage",
      undeleteMessage: "undeleteMessage",
      reportMessage: "reportMessage",
      submitSorting: "submitSorting",
      upVoteMessage: "upVoteMessage",
      replyToMessage: "replyToMessage",
      approveMessage: "approveMessage",
      downVoteMessage: "downVoteMessage",
      blockMessage: "blockMessage",
      escalateMessage: "escalateMessage",
      unLikeMessage: "unLikeMessage",
      revokeUpVoteMessage: "revokeUpVoteMessage",
      revokeDownVoteMessage: "revokeDownVoteMessage",
    }),
    turnOnIsEditing(messageId, replyMessage) {
      this.newCommentReply = replyMessage;
      this.editingId = messageId;
      this.isEditing = true;
    },
    turnOnReply() {
      this.fetchRepliesToMessage(this.messageId);
      this.isReplying = true;
    },
    toggleCommentMenu() {
      this.showMenuOptions = true;
    },
    showReportsMenu() {
      this.showMenuOptions = false;
      this.showReportOptions = true;
    },
    saveEdit() {
      if (!this.newCommentReply) {
        return;
      }
      this.isEditing = false;
      this.editMessage(this.editingId, this.newCommentReply);
    },
    cancelEditing() {
      this.isEditing = false;
    },
    deleteComment(id) {
      this.deleteMessage(id);
      this.showMenuOptions = false;
    },
    unDeleteComment(id) {
      this.undeleteMessage(id);
      this.showMenuOptions = false;
    },
    report(reportData) {
      this.showReportOptions = false;
      this.reportMessage(reportData.id, reportData.reason);
    },
    messageReply(newMessage) {
      this.replyToMessage(this.messageId, newMessage);
    },
    like(id) {
      this.likeMessage(id);
    },
    downVote(id) {
      this.downVoteMessage(id);
    },
    upVote(id) {
      this.upVoteMessage(id);
    },
    block(blockData) {
      this.blockMessage(blockData.id, blockData.reason);
    },
    approve(id) {
      this.approveMessage(id);
    },
    escalate(id) {
      this.escalateMessage(id);
    },
    revokeUpVote() {
      this.revokeUpVoteMessage(this.messageId);
    },
    revokeDownVote() {
      this.revokeDownVoteMessage(this.messageId);
    },
    unlike() {
      this.unLikeMessage(this.messageId);
    },
  },
  styleGuide: () => ({
    messageContainerBackground: { "color.background": "light" },
    borderRadius: { "size.borderRadius": "large" },
    actionBackground: { "color.background": "light" },
    actionBorderColor: { "color.border": "dark" },
    actionPadding: { "size.spacing": "small" },
    containerPadding: { "size.spacing": "small" },
  }),
};
</script>

<!-- eslint-disable-next-line -->
<style>
.message-container:has(.i-dropdown .i-dropdown-content) input {
  z-index: -1;
}
</style>
<style scoped lang="scss">
.card {
  overflow: visible;
}

.message-container {
  position: relative;
  z-index: 0;
  padding-block: v-bind("$getStyles.containerPadding");
}

.message-container:hover {
  z-index: 1;
}

:deep(.i-text p) {
  margin: 0;
}

.message-container:hover {
  background: v-bind("$getStyles.messageContainerBackground");
}

:deep(.message-actions) {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
  border-radius: v-bind("$getStyles.borderRadius");
  border: 1px solid v-bind("$getStyles.actionBorderColor");
  background: v-bind("$getStyles.actionBackground");
  transition: opacity 0.2s;
  padding: v-bind("$getStyles.actionPadding");
}

:deep(.message-actions:hover) {
  opacity: 1;
}

:deep(.message-actions:has(:deep(.message-dropdown:hover))){
  opacity: 1;
}

.message-container:hover :deep(.message-actions) {
  opacity: 1;
}
</style>

