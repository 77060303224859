// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatroom[data-v-b9662cec] {
  width: 100%;
}
[data-v-b9662cec] .infinite-scroll {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-end;
}
[data-v-b9662cec] .v3-infinite-loading {
  height: unset;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/conversations/ChatroomThread.vue","webpack://./ChatroomThread.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF;ADIA;EACE,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,yBAAA;ACDF;ADIA;EACE,aAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.chatroom {\n  width: 100%;\n}\n\n:deep(.infinite-scroll) {\n  display: flex;\n  overflow: scroll;\n  flex-direction: column;\n  justify-content: flex-end;\n}\n\n:deep(.v3-infinite-loading) {\n  height: unset;\n}\n",".chatroom {\n  width: 100%;\n}\n\n:deep(.infinite-scroll) {\n  display: flex;\n  overflow: scroll;\n  flex-direction: column;\n  justify-content: flex-end;\n}\n\n:deep(.v3-infinite-loading) {\n  height: unset;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
