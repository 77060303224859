<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_MODERATION">
    <iHeading font-size="huge">
      Publication Moderation
    </iHeading>

    <iSelect
      :model-value="commentModerationStrategy"
      return-value
      :items="validCommentModerationStrategyTypes"
      label="Comment Moderation"
      :disabled="!canCommentOnPosts"
      value-field="value"
      @update:model-value="updateCommentModerationConfig"
    />
    <!-- TODO: disable if users cannot comment on posts (pending VCL update to ISelect)-->
    <iSelect
      :model-value="ugcModerationStrategy"
      return-value
      :items="validUgcModerationStrategyTypes"
      label="User Generated Content Moderation"
      :disabled="!canUserCreatePost"
      value-field="value"
      @update:model-value="updateCommentModerationConfig"
    />
    <!-- disable if users cannot create posts (pending VCL update to ISelect)-->
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { useConfigurationStore } from "@/stores/configuration-store";
import {
  PAGE_PUBLICATION_MODERATION,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
export default {
  name: "PublicationModeration",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_MODERATION,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useConfigurationStore, ["canUserCreatePost", "canCommentOnPosts", "ugcModerationStrategy", "commentModerationStrategy"]),
    validCommentModerationStrategyTypes() {
      return [
        { name: "None", value: "none" },
      ];
    },
    validUgcModerationStrategyTypes() {
      return [
        { name: "None", value: "none" },
      ];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    async updateUgcModerationConfig(moderationStrategy) {
      if (moderationStrategy !== this.ugcModerationStrategy) {
        this.updateConfiguration({ ugcModerationStrategy: moderationStrategy });
      }
    },
    async updateCommentModerationConfig(moderationStrategy) {
      if (moderationStrategy !== this.commentModerationStrategy) {
        this.updateConfiguration({ commentModerationStrategy: moderationStrategy });
      }
    },
  },
};
</script>

<style scoped>
</style>
