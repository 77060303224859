<template>
  <iCard
    class="waitlist-card"
    :width="196"
    :height="140"
    header-background-color="striking"
    :header-height="46"
    clickable
    :style-overrides="{
      paddingTop: 'none',
      paddingBottom: 'none',
      paddingLeft: 'none',
      paddingRight: 'none',
    }"
    @click="$emit('click')"
  >
    <template #header>
      <iColumn :style-overrides="{paddingLeft: 'small'}">
        <iIcon
          :icon="icon"
          :size="standard"
          variant="secondary"
          disable-hover
        />
      </iColumn>
    </template>
    <template #body>
      <iColumn
        :style-overrides="{
          paddingLeft: 'small',
          paddingRight: 'small',
          paddingTop: 'small'
        }"
      >
        <slot />
      </iColumn>
    </template>
  </iCard>
</template>

<script>
export default {
  name: "WaitlistCard",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  emits: ["click"],
};
</script>

<style scoped>
.waitlist-card {
  &.i-card {
    @include phone {
      width: 100%;
      min-width: unset;
      max-width: unset;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
