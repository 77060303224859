<template>
  <iStyledCard
    :splash-image="post.thumbnail"
    :title=" post.post_title"
    :badge="snakeToTitle(getPostStatus(post.status))"
    :description="post.text"
    :description-length="335"
    height="hug"
    :width="350"
    :default-href="`/publication/${post.publication_id}/post/${post.post_id}?view_type=view`"
  >
    <template v-if="canMarkContentAsExclusive && post.exclusive" #after-title>
      <iColumn v-tooltip="'Content is marked as exclusive'" width="hug">
        <iIcon icon="lock" size="standard" />
      </iColumn>
    </template>
    <template #footer>
      <iRow align="between" wrap="nowrap" vertical-align="middle">
        <iColumn>
          <iButton
            v-if="post.status !== postStatus.ACTIVE"
            variant="secondary"
            width="fill"
            :is-loading="updatePostIsLoadingId === post.post_id"
            loading-spinner-position="inside"
            @click="submitPost(postStatus.ACTIVE)"
          >
            Publish
          </iButton>
          <iButton
            v-else
            width="fill"
            variant="secondary"
            :is-loading="updatePostIsLoadingId === post.post_id"
            loading-spinner-position="inside"
            @click="submitPost(postStatus.INACTIVE)"
          >
            Unpublish
          </iButton>
        </iColumn>
        <iLink width="fill" :href="`/publication/${post.publication_id}/post/${post.post_id}?view_type=edit`">
          <iButton width="fill" variant="secondary">
            Edit Post
          </iButton>
        </iLink>
      </iRow>
    </template>
  </iStyledCard>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { postStatus } from "@/enums/post-enums";
import { snakeToTitle } from "@bloglovin/vue-component-library";

export default {
  name: "PostPreview",
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      postStatus,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "updatePostIsLoadingId",
      "canMarkContentAsExclusive",
    ]),
  },
  methods: {
    snakeToTitle,
    ...mapActions(usePublicationStore, ["updatePublicationPost"]),

    submitPost(status) {
      this.updatePublicationPost(this.post.post_id, this.post.post_title, this.post.html, status);
    },

    getPostStatus(status) {
      switch (status) {
        case postStatus.ACTIVE:
          return "Published";
        case postStatus.INACTIVE:
          return "Unpublished";
        case postStatus.DRAFT:
          return "Draft";
      }

      return "Unknown";
    },
  },
};
</script>

<style scoped lang="scss">
.post-preview {
  &-card {
    flex: 1 1 30%;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    text-overflow: ellipsis;
    border-radius: 5px;
    max-width: 350px;
    min-width: 350px;

  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50%;
    overflow: hidden;
  }

  &-title {
    margin-bottom: 0;
  }

  &-status {
    padding: 8px;
    border: 1px solid #E7E5EB;
    border-radius: 4px;
  }

  &-details {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
  }

  &-content {
    text-overflow: ellipsis;
    text-wrap: none;
    max-height: 300px;
  }
}
</style>
