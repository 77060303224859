<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_COMMUNITY()"
    :title="orgProfileData.name"
    :breadcrumbs="breadcrumbs"
    page-name="Profile"
    sub-title=""
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <iButton @click="openInviteToPublicationModel">
          Invite to publication
        </iButton>
      </iRow>
      <iColumn gap="small">
        <iText variant="standard" font-size="extraLarge">
          {{ orgProfileData.name }}
        </iText>
        <iText variant="standard" font-size="small">
          {{ orgProfileData.org_description }}
        </iText>
      </iColumn>
      <iColumn gap="large">
        <iText variant="standard" font-size="large">
          Services offered
        </iText>
        <iColumn gap="small">
          <iChip v-for="service in activeServices" :key="service.service_id">
            {{ service.service_name }}
          </iChip>
        </iColumn>
      </iColumn>
      <OrgTeam :org-id="orgId" />
      <iDataTable
        v-if="isAdvertiser"
        :fields="fields"
        :items="advertiserDetails.domains"
        primary-key="advertiser_org_id"
      >
        <template #td(domain)="{data}">
          <iLink :href="getNonRelativeDomain(data.domain)" target="_blank">
            {{ data.domain }}
          </iLink>
        </template>
        <template #td(rate)="{data}">
          {{ getPayoutCellString(data.rate) }}
        </template>
      </iDataTable>
      <iColumn gap="large">
        <iText variant="standard" font-size="large">
          Publications
        </iText>
        <iDataTable :fields="publicationFields" :items="orgProfilePublications" primary-key="publication_id">
          <template #td(domain)="slotProps">
            <iLink :href="getNonRelativeDomain(slotProps.data.domain)" target="_blank">
              {{ slotProps.data.domain }}
            </iLink>
          </template>
          <template #td(actions)="slotProps">
            <ApplyToCollaborate :org-id="orgId" :publication-id="slotProps.data.publication_id" />
          </template>
        </iDataTable>
      </iColumn>
      <InviteToPublication :org-name="orgProfileData.name" :org-id="orgId" on-platform />
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_AFFILIATE_PLATFORM_SETTINGS,
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import { usePartnershipStore } from "@/stores/partnership-store";
import { usePublicationStore } from "@/stores/publication-store";
import InviteToPublication from "@/components/publication/InviteToPublication.vue";
import OrgTeam from "@/components/directory/OrgTeam.vue";
import ApplyToCollaborate from "@/components/publication/ApplyToCollaborate.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";

export default {
  name: "OrgProfilePage",
  components: {
    ApplyToCollaborate,
    OrgTeam,
    InviteToPublication,
    PageWrapper,
  },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_AFFILIATE_PLATFORM_SETTINGS,
      fields: [
        {
          key: "domain",
          label: "Domains",
        },
        {
          key: "rate",
          label: "Rate",
        },
      ],
      publicationFields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "domain",
          label: "Domain",
        },
        {
          key: "status",
          label: "Status",
        },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(useOrganizationStore, [
      "loadingOrgDetails",
      "advertiserDetails",
      "loadingAdvertiserDetails",
      "orgProfileData",
    ]),
    ...mapState(usePartnershipStore, [
      "advertiserDetails",
      "loadingAdvertiserDetails",
    ]),
    ...mapState(usePublicationStore, [
      "orgProfilePublications",
    ]),
    isAdvertiser() {
      return this.advertiserDetails.domains > 0;
    },
    activeServices() {
      return Object.values(this.orgProfileData.services_offered).filter(service => service.status === "active");
    },
  },
  created() {
    this.fetchAdvertiserDetails(this.orgId);
  },
  methods: {
    ...mapActions(usePartnershipStore, [
      "fetchAdvertiserDetails",
    ]),
    ...mapActions(useCollaborationStore, [
      "openInviteToPublicationModel",
    ]),
    getNonRelativeDomain(domain) {
      return `//${domain}`;
    },
    PAGE_COMMUNITY_COMMUNITY() {
      return PAGE_COMMUNITY_COMMUNITY;
    },
    getPayoutCellString(data) {
      if (data.payout_type === "percentage") {
        if (data.payout_value_min === data.payout_value_max) {
          return `${data.payout_value_max}%`;
        } else {
          return `${data.payout_value_min}-${data.payout_value_max}%`;
        }
      }

      if (data.payout_type === "amount") {
        if (data.payout_value_min === data.payout_value_max) {
          return `$${data.payout_value_max}`;
        } else {
          return `$${data.payout_value_min}-${data.payout_value_max}`;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
