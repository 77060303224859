<template>
  <iRow>
    <iSubHeading>Monetization</iSubHeading>
  </iRow>
  <iRow class="side-nav" overflow="scroll">
    <iMenu orientation="vertical" :active-item="activeItem" :items="parsedMonetizationNav" />
  </iRow>
</template>

<script>
import { SIDE_NAV_MONETIZATION } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { mapState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";

export default {
  name: "MonetizationSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    parsedMonetizationNav() {
      return parseUrlVars(SIDE_NAV_MONETIZATION, { "{org_id}": this.orgId });
    },
  },
};
</script>
