// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-nav[data-v-5b589968] {
  width: unset !important;
  flex-grow: 1;
}
.nav-item-padding[data-v-5b589968] {
  padding-inline: var(--5b589968-\\\$getStyles\\.contentPaddingLeft);
}
@media (max-width: 768px) {
.user-name[data-v-5b589968] {
    display: none;
}
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/MainNav.vue","webpack://./MainNav.vue","webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,YAAA;ACDF;ADGA;EACE,+DAAA;ACAF;ACNE;AFQF;IAEI,aAAA;ACCF;AACF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.main-nav {\n  width: unset!important;\n  flex-grow: 1;\n}\n.nav-item-padding {\n  padding-inline: v-bind(\"$getStyles.contentPaddingLeft\");\n}\n.user-name {\n  @include phone {\n    display: none;\n  }\n}\n",".main-nav {\n  width: unset !important;\n  flex-grow: 1;\n}\n\n.nav-item-padding {\n  padding-inline: v-bind(\"$getStyles.contentPaddingLeft\");\n}\n\n@media (max-width: 768px) {\n  .user-name {\n    display: none;\n  }\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
